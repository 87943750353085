import {ConnectionsColumns} from '../../../defs';

export const PREFERENCES_KEY = 'searched_profiles_columns';
export const availableColumns = [
  ConnectionsColumns.LastName,
  ConnectionsColumns.AddToPipeline,
  ConnectionsColumns.ConnectionsStrength,
  ConnectionsColumns.JobTitle,
  ConnectionsColumns.JobCompanyName,
  ConnectionsColumns.LocationName,
  ConnectionsColumns.Tags,
  ConnectionsColumns.ConnectedMembersCount,
];
