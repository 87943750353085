import {Dispatch, useCallback, useMemo, useState} from 'react';
import {useDebounce} from '../../../../../hooks';
import {LoadingSpinner} from '../../../../animations';
import {useFiltersContext} from '../../../FiltersContext';
import {filtersStateToRequest} from '../../../filtersStateToRequest';
import {FilterOptions, FilterState, FilterType, FilterValue} from '../../../types';
import {FilterOptionItem} from '../../filter-option-item';
import {FilterSearch} from '../../filter-search';

type Props = {
  filterConfig: FilterType;
  currentValue: FilterValue;
  selectedOptions?: FilterOptions;
  onChange: Dispatch<(prev: FilterState) => FilterState>;
  isMultipleChoice: boolean;
  searchable?: boolean;
};

export const EnumFilter = ({
  filterConfig,
  currentValue,
  selectedOptions = [],
  onChange,
  isMultipleChoice,
  searchable = true,
}: Props) => {
  const {key, decorator, options} = filterConfig;
  const isUsingAggregate = !options;

  const {filtersState, useFetchOptions, outOfStateFilters} = useFiltersContext();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const debouncedSearchQuery = useDebounce(searchQuery.trim(), 500);

  const filters = outOfStateFilters
    ? {...filtersStateToRequest(filtersState), ...outOfStateFilters}
    : filtersStateToRequest(filtersState);

  const {data: filterAggregateData, isLoading} = useFetchOptions(
    {
      filters,
      query: debouncedSearchQuery,
      field: key,
    },
    {
      enabled: isUsingAggregate,
    }
  );

  // options from aggregate
  const deliveredOptions = useMemo(
    () => (isUsingAggregate ? filterAggregateData?.items || [] : options),
    [filterAggregateData, isUsingAggregate, options]
  );

  const mergedOptions = useMemo(() => {
    const deliveredOptionsWithoutSelected = deliveredOptions.filter(
      option => !currentValue.includes(option.value)
    );
    return [...selectedOptions, ...deliveredOptionsWithoutSelected];
  }, [currentValue, deliveredOptions, selectedOptions]);

  const getLabel = useCallback(
    (value: string) => {
      return mergedOptions.find(option => option.value === value)?.label || value;
    },
    [mergedOptions]
  );

  const getCount = useCallback(
    (value: string) => {
      return mergedOptions.find(option => option.value === value)?.count;
    },
    [mergedOptions]
  );

  const getData = useCallback(
    (value: string) => {
      return mergedOptions.find(option => option.value === value)?.data;
    },
    [mergedOptions]
  );

  const updateValue = useCallback(
    (selectedValue: string) => {
      onChange(currentState => {
        let newValue: FilterValue = [];

        if (!isMultipleChoice) {
          newValue = [currentState.value[0] === selectedValue ? '' : selectedValue];
        } else {
          if (currentState.value.includes(selectedValue)) {
            newValue = currentValue.filter(v => v !== selectedValue);
          } else {
            newValue = [...currentValue, selectedValue];
          }
        }

        return {
          ...currentState,
          value: newValue,
          selectedOptions: newValue.map(value => ({
            value,
            label: getLabel(value),
            count: getCount(value),
            data: getData(value),
          })),
        };
      });
    },
    [currentValue, getCount, getData, getLabel, isMultipleChoice, onChange]
  );

  return (
    <>
      {searchable && <FilterSearch onChange={setSearchQuery} />}
      <div>
        {isLoading && <LoadingSpinner size="small" className="mx-auto my-6" />}
        {mergedOptions.map(option => {
          const {value, label, count, data} = option;
          return (
            <FilterOptionItem
              key={value}
              value={value}
              checked={currentValue.includes(option.value)}
              label={label}
              data={data}
              count={count}
              onChange={updateValue}
              decorator={decorator}
              isMultipleChoice={isMultipleChoice}
            />
          );
        })}
      </div>
    </>
  );
};
