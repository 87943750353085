import {useCallback, useEffect} from 'react';
import {segmentTrack} from '../../../../../utils';
import {Modal} from '../../../../modal';
import {ModalContent} from './ModalContent';
import {FilterListTexts, TokenList} from './types';

type Props = FilterListTexts & {
  value: TokenList;
  onCancel: () => unknown;
  onApply: (list: string[]) => unknown;
  isOpen: boolean;
  title: string;
};
export const ListFilter = ({
  onApply,
  isOpen,
  value,
  title,
  onCancel,
  modalTitle,
  modalDescription,
  tokensLabel,
}: Props) => {
  const onApplyWithAnal = useCallback(
    (newValue: TokenList) => {
      onApply(newValue);
      segmentTrack('Selector Applied', {
        label: 'filter',
        filter: title,
      });
    },
    [onApply, title]
  );

  useEffect(() => {
    if (isOpen) {
      segmentTrack('Modal Viewed', {label: 'list filter', title});
    }
  }, [isOpen, title]);

  return (
    <Modal visible={isOpen} className="w-[650px] font-light text-swarm-gray-700">
      <ModalContent
        modalTitle={modalTitle}
        modalDescription={modalDescription}
        tokensLabel={tokensLabel}
        onApply={onApplyWithAnal}
        onCancel={onCancel}
        value={value}
      />
    </Modal>
  );
};
