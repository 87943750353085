import {useCallback, useEffect, useState} from 'react';
import {useOnboardingContext} from '../../../../contexts';
import {useCurrentUser} from '../../../../hooks';
import {getConfig, segmentVirtualPageview} from '../../../../utils';
import {
  getExtensionVersion,
  getIsBlueAuthenticated,
  getUserBlueUrl,
} from '../../../../utils/extensionCommunication';
import {ExplanationScreen} from './ExplanationScreen';
import {FormScreen} from './FormScreen';
import {InstallExtensionScreen} from './InstallExtensionScreen';

enum WorkExperienceStepScreens {
  InstallExtensionScreen = 'InstallExtensionScreen',
  FormScreen = 'FormScreen',
  ExplanationScreen = 'ExplanationScreen',
}
export enum InstallationWizardSteps {
  Initial = 'Initial',
  InstallationTips = 'InstallationTips',
  LoginToLinkedin = 'LoginToLinkedin',
  ConnectedToLinkedin = 'ConnectedToLinkedin',
}

type Props = {
  isChromiumBrowser: boolean;
};

const {newOnboardingEnabled} = getConfig();

export const WorkExperienceStep = ({isChromiumBrowser}: Props) => {
  const {onFinishStep} = useOnboardingContext();
  const [currentView, setCurrentView] = useState<WorkExperienceStepScreens>(
    isChromiumBrowser
      ? WorkExperienceStepScreens.InstallExtensionScreen
      : WorkExperienceStepScreens.FormScreen
  );

  const {email} = useCurrentUser();
  const isSwarmDomain = email && email.endsWith('@theswarm.com');

  const showNewOnboarding = newOnboardingEnabled || isSwarmDomain;

  const [wizardStep, setWizardStep] = useState<InstallationWizardSteps>(InstallationWizardSteps.Initial);
  const [blueUserUrl, setBlueUserUrl] = useState<string | undefined>(undefined);
  const [extensionVersion, setExtensionVersion] = useState<string | undefined>(undefined);
  const [blueAuthenticated, setBlueAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    segmentVirtualPageview('/add-overlaps');
    getExtensionVersion().then(version => {
      setExtensionVersion(version);
      if (version) {
        getIsBlueAuthenticated().then(isAuthenticated => {
          setBlueAuthenticated(isAuthenticated);
          if (isAuthenticated) {
            getUserBlueUrl().then(url => setBlueUserUrl(url));
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    if (blueUserUrl) {
      setCurrentView(WorkExperienceStepScreens.FormScreen);
    } else if (blueAuthenticated) {
      setWizardStep(InstallationWizardSteps.ConnectedToLinkedin);
    } else if (extensionVersion) {
      setWizardStep(InstallationWizardSteps.LoginToLinkedin);
    }
  }, [blueUserUrl, extensionVersion, blueAuthenticated]);

  const goToExplanationScreen = useCallback(
    () => setCurrentView(WorkExperienceStepScreens.ExplanationScreen),
    []
  );

  const goToFormScreen = useCallback(() => setCurrentView(WorkExperienceStepScreens.FormScreen), []);

  switch (currentView) {
    case WorkExperienceStepScreens.InstallExtensionScreen:
      return (
        <InstallExtensionScreen
          onSkip={goToFormScreen}
          setBlueUserUrl={setBlueUserUrl}
          setBlueAuthenticated={setBlueAuthenticated}
          setExtensionVersion={setExtensionVersion}
          wizardStep={wizardStep}
          setWizardStep={setWizardStep}
        />
      );
    case WorkExperienceStepScreens.FormScreen:
      return (
        <FormScreen
          onSuccess={showNewOnboarding ? onFinishStep : goToExplanationScreen}
          onSkip={onFinishStep}
          blueUserUrl={blueUserUrl}
        />
      );
    case WorkExperienceStepScreens.ExplanationScreen:
      return <ExplanationScreen onFinish={onFinishStep} />;
    default:
      return <>Invalid screen</>;
  }
};
