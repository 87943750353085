import {InviteStatus, Role} from '../../types';

export enum MembersListItemStatus {
  Active = 'active',
  Invited = 'invited',
}

export type MembersListItem = {
  type: 'member' | 'invite';
  id: string; // member.id or invite.invite id
  userId?: string; // member.userId or undefined for invite
  teamId: string; // member.teamId or invite.team_id
  name: string; // user.firstName + user.lastName or invite.invite_email chunk before @
  email: string; // member.email or invite.invite_email
  role: Role; // member.role or invite.invite_role
  status: MembersListItemStatus;
  inviteStatus?: InviteStatus;
  connectionCount?: number;
  inviteSentDate?: string;
};

export enum MembersPageTabs {
  Joined = 'Joined',
  Invited = 'Invited',
  Connectors = 'Connectors',
}
