export enum StringEnvKeys {
  'ENV_NAME' = 'ENV_NAME',
  'SENTRY_DSN' = 'SENTRY_DSN',
  'API_URL' = 'API_URL',
  'COGNITO_USER_POOL_ID' = 'COGNITO_USER_POOL_ID',
  'COGNITO_USER_POOL_WEB_CLIENT_ID' = 'COGNITO_USER_POOL_WEB_CLIENT_ID',
  'COGNITO_USER_POOL_DOMAIN' = 'COGNITO_USER_POOL_DOMAIN',
  'REVISION' = 'REVISION', // injected by gitHashToEnv
  'GTM_ID' = 'GTM_ID',
  'INTERCOM_APP_ID' = 'INTERCOM_APP_ID',
  'GOOGLE_OAUTH_CLIENT_ID' = 'GOOGLE_OAUTH_CLIENT_ID',
  'GOOGLE_OAUTH_REDIRECT_SIGN_IN_URL' = 'GOOGLE_OAUTH_REDIRECT_SIGN_IN_URL',
  'GOOGLE_OAUTH_REDIRECT_SIGN_OUT_URL' = 'GOOGLE_OAUTH_REDIRECT_SIGN_OUT_URL',
  'SEGMENT_WRITE_KEY' = 'SEGMENT_WRITE_KEY',
}

export enum BooleanEnvKeys {
  'CLEAN_UI_SETTINGS' = 'CLEAN_UI_SETTINGS',
  'LEARN_MORE_CSV_DEFINED' = 'LEARN_MORE_CSV_DEFINED',
  'GO_TO_LAST_IN_PAGINATION' = 'GO_TO_LAST_IN_PAGINATION',
  'GOOGLE_BRANDING_GUIDES_BTN' = 'GOOGLE_BRANDING_GUIDES_BTN',
  'GRANT_ACCESS' = 'GRANT_ACCESS',
  'SHOW_IMPORT_PROGRESS' = 'SHOW_IMPORT_PROGRESS',
  'COMPANY_LOGOS_ON_PROFILES_LISTINGS' = 'COMPANY_LOGOS_ON_PROFILES_LISTINGS',
  'EXTEND_YOUR_SEARCH' = 'EXTEND_YOUR_SEARCH',
  'FUTURE_USER_MENU_ITEMS' = 'FUTURE_USER_MENU_ITEMS',
  'CRM_PROFILE_EXPORT' = 'CRM_PROFILE_EXPORT',
  'NEW_ONBOARDING' = 'NEW_ONBOARDING',
  'PROFILES_PAGE' = 'PROFILES_PAGE',
  'NEW_COMPANIES_PAGE' = 'NEW_COMPANIES_PAGE',
  'NEW_PIPELINES' = 'NEW_PIPELINES',
  'NEW_SAVED_SEARCH' = 'NEW_SAVED_SEARCH',
  'NEW_MY_CONNECTIONS_PAGE' = 'NEW_MY_CONNECTIONS_PAGE',
  'GENERAL_TEAM_SETTINGS' = 'GENERAL_TEAM_SETTINGS',
}

export type ConfigKeys = StringEnvKeys | BooleanEnvKeys;
