import {useCallback} from 'react';
import {updateInvite, useQueryMyInvites, UserRespondedStatus} from '../../../../api';
import {useChangeTeam} from '../../../../hooks';
import {InviteStatus} from '../../../../types';

type UseMyInvitesProps = {
  onInviteResponse?: () => void;
};
export const useMyInvites = ({onInviteResponse}: UseMyInvitesProps) => {
  const changeTeam = useChangeTeam();
  const {data: invitations = [], refetch} = useQueryMyInvites();

  const onUpdateStatus = useCallback(
    async (id: string, status: UserRespondedStatus, teamId?: string) => {
      await updateInvite(id, {status});
      refetch(); // just refresh the invites blocking not needed waiting here
      onInviteResponse?.();

      if (status === InviteStatus.Accepted && teamId) {
        changeTeam(teamId);
      }
    },
    [changeTeam, onInviteResponse, refetch]
  );

  const pendingInvitations = invitations.filter(invite => invite.invite_status === InviteStatus.Pending);

  return {invitations: pendingInvitations, refetch, onUpdateStatus};
};
