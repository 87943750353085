import {Link} from 'react-router-dom';
import {Permission} from '../../types';
import {getConfig} from '../../utils';
import {CompanyLogo} from '../company-logo';
import {PaywallClickBlocker} from '../paywall-click-blocker';
import {PermissionChecker} from '../permission';

type Props = {
  companyId?: string;
  companyName: string;
  logoUrl?: string;
  dataIntercomTarget?: string;
};

const {companyLogosOnProfilesListingsEnabled} = getConfig();
export const CompanyLink = ({companyId, companyName, logoUrl, dataIntercomTarget}: Props) => {
  if (!companyId) {
    return <span>{companyName}</span>;
  }
  const href = encodeURI(`/companies/${companyId}`);
  const link = companyLogosOnProfilesListingsEnabled ? (
    <Link
      className="flex items-center gap-2 whitespace-nowrap text-swarm-black hover:underline"
      to={href}
      {...(dataIntercomTarget && {'data-intercom-target': dataIntercomTarget})}
    >
      <CompanyLogo logoUrl={logoUrl} companyName={companyName} companyId={companyId} />
      {companyName}
    </Link>
  ) : (
    <Link
      className="hover:text-swarm-black hover:underline"
      to={href}
      {...(dataIntercomTarget && {'data-intercom-target': dataIntercomTarget})}
    >
      {companyName}
    </Link>
  );

  return (
    <PermissionChecker
      permission={Permission.Company}
      fallback={companyName}
      missingPlanFallback={<PaywallClickBlocker>{link}</PaywallClickBlocker>}
    >
      {link}
    </PermissionChecker>
  );
};
