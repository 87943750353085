import {Helmet} from 'react-helmet';
import {LoadingSpinner} from '../../components/animations';
import {NoResults} from '../../components/no-results';
import {PageHeader} from '../../components/page-header';
import {TabLinks, TabPageContent} from '../../components/tabs';
import {TrialNotification} from '../../components/trial-notification';
import {useSubscription} from '../../hooks';
import {AppLayout} from '../../layouts';
import {Subscription} from './subscription';
import {useTabsConfig} from './useTabsConfig';

export const SubscriptionPage = () => {
  const {plan, shouldRefetchPlan, isPlanLoading} = useSubscription();
  const tabs = useTabsConfig();

  return (
    <>
      <Helmet>
        <title>Subscription - The Swarm</title>
      </Helmet>
      <AppLayout
        header={<PageHeader title="Team Settings" />}
        subHeader={<TabLinks tabs={tabs} activeTab="Subscription" />}
      >
        <div className="mx-auto max-w-4xl">
          <TrialNotification />
        </div>
        <TabPageContent>
          {shouldRefetchPlan || isPlanLoading ? (
            <LoadingSpinner centered color="black" size="small" className="pt-4" />
          ) : !plan ? (
            <NoResults
              page="error"
              heading="No subscription data."
              subheading="Something went wrong while loading your subscription. Please try again later."
            />
          ) : (
            <Subscription plan={plan} />
          )}
        </TabPageContent>
      </AppLayout>
    </>
  );
};
