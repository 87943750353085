import {ReactNode, useMemo} from 'react';
import {LoadingSpinner} from '../../components/animations';
import {useOnboardingContext} from '../../contexts';
import {useCurrentUser} from '../../hooks';
import {AuthLayout} from '../../layouts';
import {OnboardingStep} from '../../types';
import {getConfig} from '../../utils';
import {
  FirstLastNameStep,
  InviteMembersManually,
  NewTeamStep,
  TeamStep,
  TeamStepScreens,
  WorkExperienceStep,
} from './steps';

const {newOnboardingEnabled} = getConfig();

type WindowWithChrome = Window & {chrome?: unknown};

export const OnboardingPage = () => {
  const {currentStep} = useOnboardingContext();
  const isChromiumBrowser = typeof (window as WindowWithChrome).chrome !== 'undefined';
  const {email} = useCurrentUser();
  const isSwarmDomain = email && email.endsWith('@theswarm.com');

  const showNewOnboarding = newOnboardingEnabled || isSwarmDomain;

  const stepsViews: Record<OnboardingStep, ReactNode> = useMemo(
    () => ({
      [OnboardingStep.Loading]: <LoadingSpinner size="large" color="black" centered className="!my-16" />,
      [OnboardingStep.FirstLastName]: <FirstLastNameStep />,
      [OnboardingStep.WorkExperience]: <WorkExperienceStep isChromiumBrowser={isChromiumBrowser} />,
      [OnboardingStep.TeamWithInvitations]: showNewOnboarding ? (
        <NewTeamStep invitationAvailable />
      ) : (
        <TeamStep />
      ),
      [OnboardingStep.TeamWithoutInvitations]: showNewOnboarding ? (
        <NewTeamStep />
      ) : (
        <TeamStep initialScreen={TeamStepScreens.CreateTeamScreen} />
      ),
      [OnboardingStep.InviteMembersManually]: <InviteMembersManually />,
    }),
    [isChromiumBrowser, showNewOnboarding]
  );

  if (!currentStep) {
    return null;
  }

  return <AuthLayout>{stepsViews[currentStep]}</AuthLayout>;
};
