import {useCallback, useEffect, useState} from 'react';
import {
  getCompanyByLi,
  getCompanyByUserLiHandler,
  mapNetwork,
  teamIdSetter,
  useMutationStartWorkOverlapsImportTask,
  useOnboardingStatus,
} from '../../../../../api';
import {LoadingSpinner} from '../../../../../components/animations';
import {CompanyStatus, OnboardingCompany} from '../../../../../types';
import {segmentVirtualPageview} from '../../../../../utils';
import {
  AIMapperEnable,
  CompanyAutoMatched,
  ManuallyCreateTeam,
  ProvideCompanyUrl,
  Ready,
} from './team-creation-stages';

type Props = {
  onFinish: () => void;
};

enum TeamCreationStages {
  Loading = 'Loading',
  CompanyAutoMatched = 'CompanyAutoMatched',
  ProvideCompanyLiUrl = 'ProvideCompanyLiUrl',
  AIMapperEnable = 'AIMapperEnable',
  ManuallyCreateTeam = 'ManuallyCreateTeam',
  Ready = 'Ready',
}

export const CreateTeamScreen = ({onFinish}: Props) => {
  const [currentStage, setCurrentStage] = useState<TeamCreationStages>(TeamCreationStages.Loading);
  const [companyData, setCompanyData] = useState<OnboardingCompany | undefined>(undefined);
  const [status, setStatus] = useState<CompanyStatus>(CompanyStatus.NotStared);
  const [aiNetworkMapperEnabled, setAiNetworkMapperEnabled] = useState(false);

  const {mutate: initializeOverlapsTask} = useMutationStartWorkOverlapsImportTask();

  const {data: onboardingStatus} = useOnboardingStatus();

  const goToProvideCompanyLiUrl = useCallback(() => {
    setStatus(CompanyStatus.NotStared);
    setCurrentStage(TeamCreationStages.ProvideCompanyLiUrl);
  }, []);

  const goToCompanyAutoMatched = useCallback(
    () => setCurrentStage(TeamCreationStages.CompanyAutoMatched),
    []
  );

  const goToReady = useCallback(() => setCurrentStage(TeamCreationStages.Ready), []);

  const goToAiMapperEnable = useCallback(() => setCurrentStage(TeamCreationStages.AIMapperEnable), []);

  const goToForm = useCallback(() => setCurrentStage(TeamCreationStages.ManuallyCreateTeam), []);

  const confirmAiNetworkMapper = useCallback(() => {
    setAiNetworkMapperEnabled(true);
    goToForm();
  }, [goToForm]);

  const detectCompanyByUserLi = useCallback(async (): Promise<OnboardingCompany | undefined> => {
    const userLiUrl = onboardingStatus?.linkedinUrl;
    let company;
    try {
      company = userLiUrl ? await getCompanyByUserLiHandler(userLiUrl) : undefined;
      if (company) {
        setCompanyData(company);
        setStatus(company.mappable ? CompanyStatus.OK : CompanyStatus.CompanyCantBeMapped);
        goToCompanyAutoMatched();
      }
    } catch {
      setStatus(CompanyStatus.NotFound);
      goToProvideCompanyLiUrl();
    }
    return company;
  }, [onboardingStatus, goToCompanyAutoMatched, goToProvideCompanyLiUrl]);

  const detectCompanyByCompanyLi = useCallback(
    async (url: string): Promise<OnboardingCompany | undefined> => {
      let company;
      try {
        company = await getCompanyByLi(url);
        setCompanyData(company);
        setStatus(company.mappable ? CompanyStatus.OK : CompanyStatus.CompanyCantBeMapped);
        goToCompanyAutoMatched();
      } catch {
        setStatus(CompanyStatus.NotFound);
      }
      return company;
    },
    [goToCompanyAutoMatched]
  );

  const onFinishForm = useCallback(
    (newTeamId: string) => {
      teamIdSetter(newTeamId);
      if (aiNetworkMapperEnabled && companyData) {
        mapNetwork(companyData.id);
      }
      initializeOverlapsTask();
      goToReady();
    },
    [goToReady, aiNetworkMapperEnabled, companyData, initializeOverlapsTask]
  );

  useEffect(() => {
    if (!onboardingStatus?.linkedinUrl) {
      goToProvideCompanyLiUrl();
    } else {
      detectCompanyByUserLi();
    }
  }, [onboardingStatus, detectCompanyByUserLi, goToProvideCompanyLiUrl]);

  useEffect(() => {
    segmentVirtualPageview('/create-team');
  }, []);

  const companyMappable = status === CompanyStatus.OK;

  switch (currentStage) {
    case TeamCreationStages.Loading:
      return <LoadingSpinner color="black" size="medium" />;
    case TeamCreationStages.CompanyAutoMatched:
      if (!companyData) {
        goToProvideCompanyLiUrl();
        return null;
      }
      return (
        <CompanyAutoMatched
          onAcceptCompany={companyMappable ? goToAiMapperEnable : goToForm}
          onDeclineCompany={goToProvideCompanyLiUrl}
          company={companyData}
        />
      );
    case TeamCreationStages.ProvideCompanyLiUrl:
      return (
        <ProvideCompanyUrl
          onSkip={goToForm}
          onVerify={detectCompanyByCompanyLi}
          unverified={status === CompanyStatus.NotFound}
        />
      );
    case TeamCreationStages.AIMapperEnable:
      return <AIMapperEnable onSkip={goToForm} onConfirm={confirmAiNetworkMapper} />;
    case TeamCreationStages.ManuallyCreateTeam:
      return <ManuallyCreateTeam onFinish={onFinishForm} teamName={companyData?.name} />;
    case TeamCreationStages.Ready:
    default:
      return <Ready onFinish={onFinish} />;
  }
};
