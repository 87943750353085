import {useEffect, useState} from 'react';
import {FiPlus as PlusIcon} from 'react-icons/fi';
import {Button} from '../../button';
import {Dropdown} from '../../dropdown';
import {Tooltip} from '../../tooltip';
import {FilterSearch} from '../filter/filter-search';
import {FiltersConfig, FiltersState} from '../types';

type Props = {
  config: FiltersConfig;
  currentFilters?: FiltersState;
  onAddFilter: (filterKey: string) => void;
};

export const AddFilter = ({config, currentFilters, onAddFilter}: Props) => {
  const {categories, filters, availableFilters} = config;
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const closeDropdown = () => setIsOpen(false);

  useEffect(() => {
    if (!isOpen) {
      setSearchQuery('');
    }
  }, [isOpen]);
  const hasEmptyFilter = Object.values(currentFilters || {}).some(filter => filter.value.length === 0);
  const unusedFilters = availableFilters.filter(filterKey => !currentFilters?.[filterKey]);
  const foundFilters = unusedFilters
    .map(filterKey => filters[filterKey])
    .filter(filter => filter.title.toLowerCase().includes(searchQuery.toLowerCase()));

  const onItemClick = (filterKey: string) => {
    onAddFilter(filterKey);
    closeDropdown();
  };

  return unusedFilters.length === 0 ? null : (
    <Dropdown
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      renderDropdownTrigger={() => (
        <Tooltip
          content="Complete the missing value in your filter before adding another"
          disabled={!hasEmptyFilter}
        >
          <Button
            disabled={hasEmptyFilter}
            variant="tertiary"
            icon={<PlusIcon size={16} />}
            outline
            size="md"
            tracking={{label: 'add filter'}}
            intercomTarget="add-filter-button"
          >
            Add Filter
          </Button>
        </Tooltip>
      )}
    >
      <div className="flex flex-col gap-2">
        <FilterSearch onChange={setSearchQuery} />
        <div>
          {categories.map(category => {
            const categoryItems = foundFilters.filter(filter => filter.category === category.key);
            return (
              categoryItems.length !== 0 && (
                <Dropdown.ItemsGroup
                  key={category.key}
                  title={category.title}
                  items={categoryItems.map(item => ({
                    label: item.title,
                    icon: item.icon?.(),
                    type: {
                      name: 'button',
                      onClick: () => onItemClick(item.key),
                    },
                    dataIntercomTarget: item.title,
                    permission: item.permission,
                  }))}
                />
              )
            );
          })}
        </div>
      </div>
    </Dropdown>
  );
};
