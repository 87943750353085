import {isEqual} from 'lodash';
import {FiSave as SaveIcon, FiX as XIcon} from 'react-icons/fi';
import {updatePipeline} from '../../../api';
import {Button} from '../../../components/button';
import {ColumnSettingsDropdown} from '../../../components/column-settings';
import {notify} from '../../../components/notifications';
import {PaginationButtons} from '../../../components/pagination-buttons';
import {RequestIntro, useRequestIntroModal} from '../../../components/request-intro';
import {useTableFilters, useTablePagination, useTableSorting} from '../../../components/table';
import {TabLinks} from '../../../components/tabs';
import {ConnectionsColumns, connectionsColumnsLabelsMap as labelsMap} from '../../../defs';
import {useCheckPermission, useColumnSettings, useCurrentUser, useOnFilteringEvent} from '../../../hooks';
import {AppLayout} from '../../../layouts';
import {MyConnectionsFilter, Permission, PipelineCommonResponse} from '../../../types';
import {Filters} from '../../my-connections/Filters';
import {PipelineHeader} from '../components/PipelineHeader';
import {getTabLinksConfig} from '../getTabLinksConfig';
import {availableColumns, PREFERENCES_KEY} from './columns';
import {SearchedProfiles} from './SearchedProfiles';

type Props = {
  pipeline: PipelineCommonResponse;
  refetchPipeline: () => void;
};

export const SavedSearch = ({pipeline, refetchPipeline}: Props) => {
  const {introModalVisible, closeIntroModal, openIntroModal, introProfileId} = useRequestIntroModal();
  const {id, savedSearch, title} = pipeline;
  const {sortingState, setSorting} = useTableSorting();
  const defaultFilters = {...JSON.parse(savedSearch), out_pipeline: [id]};
  const tableFilters = useTableFilters<MyConnectionsFilter>(defaultFilters);
  const {filters: savedSearchFilters, clearFilters} = tableFilters;
  const {columnSettings, setColumnSettings, visibleColumnsState, columnsOrder} = useColumnSettings(
    availableColumns,
    PREFERENCES_KEY
  );

  const {id: currentUserId} = useCurrentUser();
  const isOwnPipeline = currentUserId === pipeline.creator.id;
  const canTeamPipelineUpdate = useCheckPermission(Permission.TeamPipelineUpdate);

  useOnFilteringEvent({
    filters: savedSearchFilters,
    filtersToOmit: ['out_pipeline'],
  });

  const saveSearch = async () => {
    await updatePipeline(
      {
        ...pipeline,
        savedSearch: JSON.stringify(tableFilters.filters),
      },
      id
    );
    refetchPipeline();
    notify('Filters combination saved.');
  };

  const clearSavedSearchChanges = () => {
    clearFilters();
    notify('Saved search changes undone.');
  };

  const {
    currentPage,
    paginationState,
    setPaginationState,
    totalCount,
    setMeta,
    visibleItemsString,
    isFirstPage,
    isLastPage,
    nextPage,
    previousPage,
    goToPage,
  } = useTablePagination();

  const paginationFullInfo = `${visibleItemsString} connections`;

  const tabLinksConfig = getTabLinksConfig(id);

  const operationsDisabled = isEqual(savedSearchFilters, defaultFilters);
  return (
    <AppLayout
      header={<PipelineHeader pipeline={pipeline} />}
      subHeader={
        <>
          <TabLinks tabs={tabLinksConfig} activeTab="Saved search" />
          <div className="flex items-end justify-between">
            <div>
              <Filters tableFilters={tableFilters} teamGraph={true} noClear />
            </div>
            <div className="mb-2 mr-2">
              <ColumnSettingsDropdown
                availableColumns={availableColumns}
                fixedColumns={[ConnectionsColumns.LastName, ConnectionsColumns.AddToPipeline]}
                columnSettings={columnSettings}
                setColumnSettings={setColumnSettings}
                labelsMap={labelsMap}
              />
            </div>
          </div>
          <div className="mb-3 mt-1 flex gap-1">
            {(isOwnPipeline || canTeamPipelineUpdate) && (
              <Button
                data-intercom-target="Save this search"
                onClick={saveSearch}
                variant="tertiary"
                icon={<SaveIcon />}
                disabled={operationsDisabled}
                tracking={{
                  label: 'save this search',
                  location: 'pipeline',
                }}
              >
                Save this search
              </Button>
            )}
            <Button
              data-intercom-target="Clear saved search changes"
              onClick={clearSavedSearchChanges}
              variant="tertiary"
              icon={<XIcon />}
              disabled={operationsDisabled}
              tracking={{
                label: 'clear changes',
                location: 'pipeline',
              }}
            >
              Clear changes
            </Button>
          </div>
        </>
      }
      footer={
        totalCount > 0 && (
          <PaginationButtons
            visibleItemsString={paginationFullInfo}
            pageIndex={currentPage}
            isFirstPage={isFirstPage}
            isLastPage={isLastPage}
            navigate={{
              next: nextPage,
              previous: previousPage,
              goToPage: goToPage,
            }}
            totalCount={totalCount}
            pageSize={paginationState.pageSize}
          />
        )
      }
    >
      <SearchedProfiles
        tableFilters={tableFilters}
        pagination={{
          paginationState,
          setPaginationState,
          currentPage,
          totalCount,
          setMeta,
        }}
        sorting={{
          sortingState,
          setSorting,
        }}
        openIntroModal={openIntroModal}
        pipelineId={id}
        pipelineName={title}
        visibleColumnsState={visibleColumnsState}
        columnsOrder={columnsOrder}
        availableColumns={availableColumns}
      />
      <RequestIntro profileId={introProfileId} visible={introModalVisible} onClose={closeIntroModal} />
    </AppLayout>
  );
};
