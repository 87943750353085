import {ColumnDef} from '@tanstack/react-table';
import {useCallback, useMemo} from 'react';
import {useIntercom} from 'react-use-intercom';
import {Avatar} from '../../components/avatar';
import {Button} from '../../components/button';
import {NoResults} from '../../components/no-results';
import {Pill} from '../../components/pill';
import {Table, TableInnerHeader, useSwarmTable, useTablePagination} from '../../components/table';
import {TruncateWithTooltip} from '../../components/truncate-with-tooltip';
import {useCheckPermission, useTeamMembers} from '../../hooks';
import {Permission, Role} from '../../types';
import {AddMembersButton} from './components';
import Styles from './Members.module.scss';
import {MembersListItem, MembersListItemStatus} from './types';

type Props = {
  members: MembersListItem[];
};

export const ConnectorsMembersList = ({members}: Props) => {
  const connectors = members
    .filter(member => member.role === Role.Connector)
    .sort((a, b) => (b.connectionCount || 0) - (a.connectionCount || 0));

  const shouldDisplayOptions = useCheckPermission(Permission.TeamMemberUpdate);

  const pagination = useTablePagination();

  const columns = useMemo<ColumnDef<MembersListItem>[]>(
    () => [
      {
        accessorKey: 'name',
        header: () => <TableInnerHeader label="Name" />,
        cell: info => {
          const {name, status} = info.row.original;
          return (
            <div className={Styles.nameCellWrapper}>
              <Avatar fullname={name} size="md" outline empty />
              <span className={Styles.nameWrapper}>
                <TruncateWithTooltip content={name}>{name}</TruncateWithTooltip>
              </span>
              {status === MembersListItemStatus.Invited && <Pill color="gray">Invited</Pill>}
            </div>
          );
        },
        size: 250,
      },
      {
        accessorKey: 'connectionCount',
        header: () => <TableInnerHeader label="Connections" />,
        cell: info => {
          const connectionCount = (info.row.original.connectionCount || 0).toLocaleString('en-US');
          return <span>{connectionCount}</span>;
        },
        size: 120,
        meta: {
          truncate: true,
        },
      },
      {
        accessorKey: 'role',
        header: () => <TableInnerHeader label="Role" />,
        cell: () => <span data-intercom-target="role Connector">Connector</span>,
        size: 140,
        meta: {
          truncate: true,
        },
      },
      ...(shouldDisplayOptions
        ? ([
            {
              accessorKey: '',
              header: () => <TableInnerHeader label="Actions" />,
              cell: ({row}) => <ConnectorsActions connector={row.original} />,
              size: 200,
              enableResizing: false,
              id: 'actions',
            },
          ] satisfies ColumnDef<MembersListItem>[])
        : []),
    ],
    [shouldDisplayOptions]
  );

  const table = useSwarmTable<MembersListItem>({
    uniqueName: 'connectorsMembers',
    rows: connectors,
    columns,
    defaultColumnSize: 250,
    defaultColumnMinSize: 50,
    isLoaded: true,
    pagination,
  });

  if (!connectors.length) {
    return (
      <NoResults page="pipeline" heading="No connectors." subheading="Add members to grow your Swarm.">
        <AddMembersButton onSuccess={() => {}} />
      </NoResults>
    );
  }

  return (
    <Table
      headerGroups={table.getHeaderGroups()}
      rowModel={table.getRowModel()}
      totalWidth={table.getTotalSize()}
      cellHeight={60}
    />
  );
};

type ConnectorActionsProps = {
  connector: MembersListItem;
};

const ConnectorsActions = ({connector}: ConnectorActionsProps) => {
  const {onDeleteMember} = useTeamMembers();
  const {showNewMessage} = useIntercom();

  const deleteConnector = useCallback(() => onDeleteMember(connector), [onDeleteMember, connector]);

  return (
    <div className="gap-2">
      <Button
        intercomTarget="Add email"
        tracking={{label: 'Add email'}}
        onClick={() => {
          showNewMessage(`I would like to add email to my Connector member (ID: ${connector.id})`);
        }}
        size="xs"
        variant="tertiary"
        outline
      >
        Add email
      </Button>
      <Button size="xs" variant="tertiary" outline onClick={deleteConnector} tracking={{label: 'Remove'}}>
        Remove
      </Button>
    </div>
  );
};
