import {ReactNode} from 'react';
import {Placement} from 'react-laag';
import {UpgradeTooltipContentLocation} from '../../types';
import {Tooltip} from '../tooltip';
import {UpgradeTooltipContent} from './UpgradeTooltipContent';

type Props = {
  featureName: string;
  placement?: Placement;
  children: ReactNode;
  location: UpgradeTooltipContentLocation;
};

export const UpgradeTooltip = ({featureName, children, placement, location}: Props) => (
  <Tooltip
    content={<UpgradeTooltipContent featureName={featureName} location={location} />}
    placement={placement}
  >
    {children}
  </Tooltip>
);
