import {useQuery} from 'react-query';
import {DashboardData} from '../types';
import {apiClient} from './apiClient';
import {getConfig} from '../utils';
const {profilesPageEnabled} = getConfig();

const getDashboard = async (): Promise<DashboardData> =>
  apiClient
    .get<DashboardData>('/dashboard', {
      params: {
        newProfileCountGetter: profilesPageEnabled,
      },
    })
    .then(res => res.data);

export const useQueryDashboard = () => useQuery(['getDashboard'], () => getDashboard());
