import {ProfilesBffColumns} from '../../defs';

export const PEOPLE_COLUMNS_PREFERENCES_KEY = 'people_columns';
export const availableColumns = [
  ProfilesBffColumns.FullName,
  ProfilesBffColumns.JobTitle,
  ProfilesBffColumns.JobCompanyName,
  ProfilesBffColumns.LocationName,
  ProfilesBffColumns.Tags,
  ProfilesBffColumns.ConnectionsStrength,
  ProfilesBffColumns.ConnectedMembersCount,
];
