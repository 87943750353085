import {useEffect, useMemo} from 'react';
import {Helmet} from 'react-helmet';
import {FiSearch as SearchIcon} from 'react-icons/fi';
import {ColumnSettingsDropdown} from '../../components/column-settings';
import {Input} from '../../components/form';
import {PageHeader} from '../../components/page-header';
import {PaginationButtons} from '../../components/pagination-buttons';
import {RequestIntro, useRequestIntroModal} from '../../components/request-intro';
import {useTableFilters, useTablePagination, useTableSearch, useTableSorting} from '../../components/table';
import {ConnectionsColumns, connectionsColumnsLabelsMap as labelsMap} from '../../defs';
import {useColumnSettings, useOnFilteringEvent, useOnTeamChanged, usePrevious} from '../../hooks';
import {AppLayout} from '../../layouts';
import {MyConnectionsFilter, MyConnectionsFilterKeys, ScopeFilterOptions} from '../../types';
import {
  availableConnectionsColumns,
  availableMyConnectionsColumns,
  CONNECTIONS_COLUMNS_PREFERENCES_KEY,
  MY_CONNECTIONS_COLUMNS_PREFERENCES_KEY,
} from './columns';
import {HeaderButtons} from './components';
import {Filters} from './Filters';
import {MyConnectionsTable} from './MyConnectionsTable';

type Props = {
  teamGraph?: boolean;
};

export const MyConnectionsPage = ({teamGraph = false}: Props) => {
  const {introModalVisible, closeIntroModal, openIntroModal, introProfileId} = useRequestIntroModal();
  const {sortingState, setSorting} = useTableSorting([{id: 'connections_strength', desc: true}]);
  const {searchQuery, onChangeSearchQuery, searchQueryFetchParam, onClearSearchQuery} = useTableSearch();
  const availableColumns = useMemo(
    () => (teamGraph ? availableConnectionsColumns : availableMyConnectionsColumns),
    [teamGraph]
  );
  const {columnSettings, setColumnSettings, visibleColumnsState, columnsOrder} = useColumnSettings(
    availableColumns,
    teamGraph ? CONNECTIONS_COLUMNS_PREFERENCES_KEY : MY_CONNECTIONS_COLUMNS_PREFERENCES_KEY
  );

  const defaultFilters = useMemo(
    () => ({
      [MyConnectionsFilterKeys.Scope]: [ScopeFilterOptions.InNetwork],
    }),
    []
  );

  const tableFilters = useTableFilters<MyConnectionsFilter>(defaultFilters);

  const {
    currentPage,
    paginationState,
    setPaginationState,
    setPageSize,
    resetPagination,
    totalCount,
    setMeta,
    visibleItemsString,
    isFirstPage,
    isLastPage,
    nextPage,
    previousPage,
    goToPage,
  } = useTablePagination();

  useOnFilteringEvent({
    filters: tableFilters.filters,
    filtersToOmit: [MyConnectionsFilterKeys.Scope],
    searchQuery: searchQueryFetchParam,
  });

  const prevTeamGraph = usePrevious(teamGraph);
  useEffect(() => {
    if (prevTeamGraph !== undefined && prevTeamGraph !== teamGraph) {
      resetPagination();
    }
  }, [prevTeamGraph, resetPagination, teamGraph]);

  // Cleanup filters when team changes
  useOnTeamChanged(tableFilters.clearFilters);

  const hasConnections = totalCount > 0;

  const paginationFullInfo = `${visibleItemsString} connections`;

  return (
    <>
      <Helmet>
        <title>{!teamGraph ? 'My Connections - The Swarm' : 'Connections - The Swarm'}</title>
      </Helmet>
      <AppLayout
        header={
          <PageHeader
            title={!teamGraph ? 'My Connections' : 'Connections'}
            rightSlot={<HeaderButtons teamGraph={teamGraph} hasConnections={hasConnections} />}
            hasBottomSeparator
          >
            <Input
              icon={SearchIcon}
              type="text"
              value={searchQuery}
              onChange={e => onChangeSearchQuery(e.target.value)}
              onClear={onClearSearchQuery}
              className="max-w-sm"
              placeholder="Search by name i.e. John Doe"
              intercomTarget="search"
            />
          </PageHeader>
        }
        subHeader={
          <div className="flex items-end justify-between">
            <div>
              <Filters
                tableFilters={tableFilters}
                teamGraph={teamGraph}
                noClear={tableFilters.filters === defaultFilters}
              />
            </div>
            <div className="mb-2 mr-2">
              <ColumnSettingsDropdown
                availableColumns={availableColumns}
                fixedColumns={[ConnectionsColumns.LastName]}
                columnSettings={columnSettings}
                setColumnSettings={setColumnSettings}
                labelsMap={labelsMap}
              />
            </div>
          </div>
        }
        footer={
          hasConnections && (
            <PaginationButtons
              visibleItemsString={paginationFullInfo}
              pageIndex={currentPage}
              isFirstPage={isFirstPage}
              isLastPage={isLastPage}
              navigate={{
                next: nextPage,
                previous: previousPage,
                goToPage: goToPage,
              }}
              totalCount={totalCount}
              pageSize={paginationState.pageSize}
              setPageSize={setPageSize}
            />
          )
        }
      >
        <MyConnectionsTable
          teamGraph={teamGraph}
          searchQuery={searchQueryFetchParam}
          tableFilters={tableFilters}
          pagination={{
            paginationState,
            setPaginationState,
            currentPage,
            totalCount,
            setMeta,
          }}
          sorting={{
            sortingState,
            setSorting,
          }}
          openIntroModal={openIntroModal}
          visibleColumnsState={visibleColumnsState}
          columnsOrder={columnsOrder}
          availableColumns={availableColumns}
        />
        {teamGraph && (
          <RequestIntro profileId={introProfileId} onClose={closeIntroModal} visible={introModalVisible} />
        )}
      </AppLayout>
    </>
  );
};
