import {Children, isValidElement, ReactNode} from 'react';
import Styles from './Switcher.module.scss';
import {SwitcherContextProvider} from './SwitcherContext';

type Props = {
  value: string | undefined;
  setValue: (value: string | undefined) => void;
  children: ReactNode;
};

export const Switcher = ({value, setValue, children}: Props) => {
  const validChildren = Children.toArray(children).filter(isValidElement);

  return (
    <SwitcherContextProvider selectedValue={value} setValue={setValue}>
      <div className={Styles.switcher}>{validChildren}</div>
    </SwitcherContextProvider>
  );
};
