import {useCallback, useMemo} from 'react';
import {BulkAddToParticularPipelineButton} from '../../../components/add-to-pipeline';
import {Table} from '../../../components/table';
import {ProfilesListRow} from '../../../types';
import {type UsePipelinesSavedSearchTableResult} from './usePipelinesSavedSearchTable';

type Props = {
  tableData: UsePipelinesSavedSearchTableResult;
  pipelineId: string;
  pipelineName: string;
  onChangeSuccess: () => void;
};

export const PipelinesSavedSearchTable = ({tableData, pipelineId, pipelineName, onChangeSuccess}: Props) => {
  const selectedRows = tableData.getSelectedRowModel().rows;
  const selectedProfiles = useMemo(() => selectedRows.map(row => row.original), [selectedRows]);
  const selectedProfileIds = useMemo(() => selectedProfiles.map(profile => profile.id), [selectedProfiles]);
  const selectedProfilesCount = selectedProfileIds.length;
  const shouldShowBulkActions = selectedProfilesCount > 0;

  const onBulkActionSuccess = useCallback(() => {
    onChangeSuccess();
    tableData.toggleAllRowsSelected(false);
  }, [onChangeSuccess, tableData]);

  return (
    <Table<ProfilesListRow>
      headerGroups={tableData.getHeaderGroups()}
      rowModel={tableData.getRowModel()}
      totalWidth={tableData.getTotalSize()}
      bulkActionsSlot={
        shouldShowBulkActions && (
          <BulkAddToParticularPipelineButton
            profileIDs={selectedProfileIds}
            onSuggestionsChangeSuccess={onBulkActionSuccess}
            pipelineId={pipelineId}
            pipelineName={pipelineName}
          />
        )
      }
    />
  );
};
