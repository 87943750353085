import {Dialog} from '@headlessui/react';
import {useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {useIntercom} from 'react-use-intercom';
import {getCompanyByLi, mapNetwork} from '../../../api';
import {useShouldOpenImportModal} from '../../../hooks';
import {segmentTrack} from '../../../utils';
import {Button} from '../../button';
import {Input} from '../../form';

type MapCompanyFormState = {
  linkedInURL: string;
};

type Props = {
  onClose: () => void;
  onSuccess: () => void;
};

const ERROR_MESSAGE = "We couldn't verify your company. Check if your link is correct.";

export const ImportConnectionsMapCompanyForm = ({onClose, onSuccess}: Props) => {
  const {turnOffShowingModal} = useShouldOpenImportModal();
  const {showArticle} = useIntercom();

  const {
    formState: {isSubmitting, errors},
    register,
    handleSubmit,
    setError,
  } = useForm<MapCompanyFormState>();

  const onSubmit = useCallback(
    async ({linkedInURL}: MapCompanyFormState) => {
      let company;
      try {
        company = await getCompanyByLi(linkedInURL);
        if (company.mappable) {
          mapNetwork(company.id).then(onSuccess);
        }
      } catch {
        setError('linkedInURL', {message: ERROR_MESSAGE});
      }
    },
    [setError, onSuccess]
  );

  const onClickReadMore = useCallback(() => {
    showArticle(9847654);
  }, [showArticle]);

  const onCancelButtonClick = useCallback(() => {
    onClose();
    turnOffShowingModal();
    segmentTrack('Button clicked', {
      label: 'Cancel',
      location: 'AI network mapper form',
    });
  }, [onClose, turnOffShowingModal]);

  return (
    <>
      <Dialog.Title as="h3" className="text-3xl font-bold text-swarm-black">
        AI Network Mapper
      </Dialog.Title>

      <Dialog.Description className="mb-4 mt-2 font-normal">
        Add your Company LinkedIn URL and build your Connections network!
      </Dialog.Description>

      <img className="w-full" src="/images/ai-network-mapper.webp" alt="network mapper" />

      <Dialog.Description className="mt-4 font-normal">
        Our system will automatically map your professional network and organisation based on work history,
        education, investments, and public data.{' '}
        <a href="#" onClick={onClickReadMore} className="text-base text-swarm-black underline">
          Learn more.
        </a>
      </Dialog.Description>

      <form className="pt-6" onSubmit={handleSubmit(onSubmit)}>
        <fieldset className="rounded bg-swarm-gray-100 p-4">
          <span className="font-medium text-swarm-black">Paste in your Company Linkedin URL</span>
          <Input
            {...register('linkedInURL')}
            type="text"
            placeholder="https://www.linkedin.com/company"
            isInvalid={Boolean(errors.linkedInURL)}
            message={errors.linkedInURL?.message}
          />
        </fieldset>

        <div className="mt-8 flex justify-end gap-2">
          <Button onClick={onCancelButtonClick} type="reset">
            Cancel
          </Button>

          <Button variant="primary" type="submit" loading={isSubmitting}>
            Continue
          </Button>
        </div>
      </form>
    </>
  );
};
