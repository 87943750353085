import {Helmet} from 'react-helmet';
import {Navigate} from 'react-router-dom';
import {PageHeader} from '../../components/page-header';
import {TabLinks} from '../../components/tabs';
import {TabPageContent} from '../../components/tabs/';
import {AppLayout} from '../../layouts';
import {getConfig} from '../../utils';
import {TeamProfile} from './general';
import {useTabsConfig} from './useTabsConfig';

const {generalTeamSettingsEnabled} = getConfig();

export const TeamSettingsPage = () => {
  const tabs = useTabsConfig();
  const redirectPath = tabs[0].path;

  if (!generalTeamSettingsEnabled) {
    return <Navigate to={redirectPath} replace={true} />;
  }

  return (
    <>
      <Helmet>
        <title>General Team Settings - The Swarm</title>
      </Helmet>
      <AppLayout
        header={<PageHeader title="Team Settings" />}
        subHeader={<TabLinks tabs={tabs} activeTab="General" />}
      >
        <TabPageContent heading="Team profile" subheading="Personalize how your team is presented to members">
          <TeamProfile />
        </TabPageContent>
      </AppLayout>
    </>
  );
};
