import {createContext, ReactNode, useContext} from 'react';
import {UseQueryOptions, UseQueryResult} from 'react-query';
import {BffAggregateRequest, BffAggregateResponse, BffFilterOptions} from '../../types';
import {FiltersState} from './types';

export type FiltersContextType = {
  filtersState: FiltersState;
  useFetchOptions: (
    getAggregateInput: BffAggregateRequest,
    options?: UseQueryOptions<BffAggregateResponse>
  ) => UseQueryResult<BffAggregateResponse, unknown>;
  outOfStateFilters?: BffFilterOptions;
};

export const FiltersContext = createContext<FiltersContextType>({} as FiltersContextType);
FiltersContext.displayName = 'SwarmFiltersContext';

type Props = FiltersContextType & {
  children: ReactNode;
};
export const FiltersContextProvider = ({
  filtersState,
  useFetchOptions,
  outOfStateFilters,
  children,
}: Props) => {
  return (
    <FiltersContext.Provider value={{filtersState, useFetchOptions, outOfStateFilters}}>
      {children}
    </FiltersContext.Provider>
  );
};

export const useFiltersContext = () => {
  const contextValue = useContext(FiltersContext);

  return contextValue;
};
