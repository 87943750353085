import {SortingState} from '@tanstack/react-table';
import {isEqual, uniqBy} from 'lodash';
import {useCallback, useEffect, useMemo} from 'react';
import {Helmet} from 'react-helmet';
import {FiSearch as SearchIcon} from 'react-icons/fi';
import {useQueryBffProfilesList, useQueryProfileListBffAggregate} from '../../api';
import {LoadingSpinner} from '../../components/animations';
import {ColumnSettingsDropdown} from '../../components/column-settings';
import {Input} from '../../components/form';
import {filtersStateToRequest, FiltersWrapper, useFiltersState} from '../../components/new-filters';
import {NoResults} from '../../components/no-results';
import {PageHeader} from '../../components/page-header';
import {PaginationButtons} from '../../components/pagination-buttons';
import {useTablePagination, useTableSearch, useTableSorting} from '../../components/table';
import {useAdjustSortToQuery} from '../../components/table/useAdjustSortToQuery';
import {ProfilesBffColumns, profilesBffLabelsMap as labelsMap} from '../../defs';
import {
  useColumnSettings,
  useCurrentUser,
  useOnFilteringEvent,
  usePrevious,
  useRestoreScrollOnPopState,
} from '../../hooks';
import {AppLayout} from '../../layouts';
import {GetProfilesListRequest, GetProfilesListResponse} from '../../types';
import {getRequestOffset, pluralizedPeople, sortingStateToSortOptions} from '../../utils';
import {availableColumns, MY_CONNECTIONS_COLUMNS_PREFERENCES_KEY} from './columns';
import {HeaderButtons} from './components';
import {filtersConfig} from './filtersConfig';
import {MyConnectionsTable} from './MyConnectionsTable';
import {useMyConnectionsTable} from './useMyConnectionsTable';

const emptyProfilesResponse: GetProfilesListResponse = {
  items: [],
  meta: {totalCount: 0, limit: 0, offset: 0},
};

const subSorting: SortingState = [
  {id: 'connectionStrength', desc: true},
  {id: 'lastName', desc: false},
  {id: 'firstName', desc: false},
];

const defaultSorting: SortingState = [subSorting[0]];

export const MyConnectionsPage = () => {
  const {filtersState, setFilter} = useFiltersState({}, filtersConfig);
  const {id: currentUserId} = useCurrentUser();

  const {columnSettings, setColumnSettings, visibleColumnsState, columnsOrder} = useColumnSettings(
    availableColumns,
    MY_CONNECTIONS_COLUMNS_PREFERENCES_KEY
  );
  const {searchQuery, onChangeSearchQuery, onClearSearchQuery, searchQueryFetchParam} = useTableSearch();

  const {
    currentPage,
    paginationState,
    setPaginationState,
    setPageSize,
    totalCount,
    setMeta,
    visibleItemsString,
    isFirstPage,
    isLastPage,
    nextPage,
    previousPage,
    goToPage,
  } = useTablePagination();

  const {sortingState, setSorting} = useTableSorting(defaultSorting);
  const sort = useMemo(
    () => sortingStateToSortOptions(uniqBy([...sortingState, ...subSorting], item => item.id)),
    [sortingState]
  );

  const adjustedSort = useAdjustSortToQuery(sort, searchQueryFetchParam);

  const offset = getRequestOffset(paginationState.pageSize, paginationState.pageIndex);

  const filters = useMemo(() => {
    return {
      ...filtersStateToRequest(filtersState),
      ...(searchQueryFetchParam ? {query: searchQueryFetchParam} : {}),
      connectedTo: [currentUserId],
    };
  }, [currentUserId, filtersState, searchQueryFetchParam]);

  useOnFilteringEvent({
    filters,
  });

  const searchParams: GetProfilesListRequest = useMemo(
    () => ({
      offset,
      limit: paginationState.pageSize,
      filters,
      sort: adjustedSort,
      teamGraph: false,
    }),
    [offset, paginationState.pageSize, filters, adjustedSort]
  );

  const {
    data = emptyProfilesResponse,
    isFetchedAfterMount,
    refetch,
  } = useQueryBffProfilesList(searchParams, {
    onSuccess: ({meta}) => setMeta(meta),
  });

  const table = useMyConnectionsTable({
    rows: data.items,
    isLoaded: isFetchedAfterMount,
    pagination: {
      paginationState,
      setPaginationState,
      currentPage,
      totalCount,
      setMeta,
    },
    sorting: {
      sortingState,
      setSorting,
    },
    refetchProfiles: refetch,
    visibleColumnsState,
    columnsOrder,
  });

  useRestoreScrollOnPopState('people', isFetchedAfterMount);

  const returnToFirstPage = useCallback(() => table.setPageIndex(1), [table]);

  const prevFilters = usePrevious(filters);
  useEffect(() => {
    if (prevFilters && !isEqual(prevFilters, filters)) {
      returnToFirstPage();
    }
  }, [filters, prevFilters, returnToFirstPage]);

  const paginationFullInfo = `${visibleItemsString} ${pluralizedPeople(totalCount)}`;

  const hasProfiles = totalCount > 0;

  return (
    <>
      <Helmet>
        <title>My Connections - The Swarm</title>
      </Helmet>
      <AppLayout
        header={
          <PageHeader rightSlot={<HeaderButtons />} gray>
            <Input
              icon={SearchIcon}
              type="text"
              value={searchQuery}
              onChange={e => onChangeSearchQuery(e.target.value)}
              onClear={onClearSearchQuery}
              className="max-w-lg"
              placeholder="Search for profile name, location, LinkedIn URL"
              intercomTarget="search"
            />
          </PageHeader>
        }
        subHeader={
          <>
            <div className="mb-2 mt-4 flex items-center gap-4 pl-2">
              <h1 className="text-2xl font-semibold capitalize">My Connections </h1>
            </div>
            <div className="mb-2 flex items-end justify-between pl-2">
              <FiltersWrapper
                filtersState={filtersState}
                onFilterSet={setFilter}
                filtersConfig={filtersConfig}
                useFetchOptions={useQueryProfileListBffAggregate}
                outOfStateFilters={{
                  ...(searchQueryFetchParam ? {query: searchQueryFetchParam} : {}),
                  connectedTo: [currentUserId],
                }}
              />
              <div className="m-2">
                <ColumnSettingsDropdown
                  availableColumns={availableColumns}
                  columnSettings={columnSettings}
                  setColumnSettings={setColumnSettings}
                  fixedColumns={[ProfilesBffColumns.FullName]}
                  labelsMap={labelsMap}
                />
              </div>
            </div>
          </>
        }
        footer={
          hasProfiles && (
            <PaginationButtons
              visibleItemsString={paginationFullInfo}
              pageIndex={currentPage}
              isFirstPage={isFirstPage}
              isLastPage={isLastPage}
              navigate={{
                next: nextPage,
                previous: previousPage,
                goToPage: goToPage,
              }}
              totalCount={totalCount}
              pageSize={paginationState.pageSize}
              setPageSize={setPageSize}
            />
          )
        }
      >
        {!isFetchedAfterMount ? (
          <LoadingSpinner size="small" color="black" centered />
        ) : !hasProfiles ? (
          <NoResults
            heading="Sorry, there are no results."
            subheading={
              'Please clear search filters and try again.\nConsider adding members to expand your network.'
            }
            page="results"
          ></NoResults>
        ) : (
          <MyConnectionsTable tableData={table} onChangeSuccess={refetch} />
        )}
      </AppLayout>
    </>
  );
};
