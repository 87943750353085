import cs from 'classnames';
import {useCallback, useMemo} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {requestIntro, updateStatus, useQueryPipelines} from '../../../api';
import {useCurrentTeam} from '../../../hooks';
import {Permission, PipelineCommonResponse, PipelineStatus} from '../../../types';
import {
  addHttpsToUrl,
  fillTemplate,
  isVariableUsedInTemplate,
  linkify,
  segmentTrack,
  verifyTemplate,
} from '../../../utils';
import {AvatarWithFullname} from '../../avatar';
import {Button} from '../../button';
import {Input, Message, Multiselect, TemplateEditor} from '../../form';
import {FormWrapper} from '../../form-wrapper';
import {notify} from '../../notifications';
import {PermissionChecker} from '../../permission';
import {Pill} from '../../pill';
import {Score} from '../../score';
import {FieldRow} from '../field-row';
import {useRequestIntroLog} from '../hooks';
import {RequestIntroFormProps, RequestIntroFormType} from '../types';
import {DEFAULT_REQUEST_INTRO_MESSAGE_KEY} from './consts';
import {Forwardable} from './Forwardable';
import {getDefaultMessage} from './getDefaultMessage';
import {getIntroBody} from './getIntroBody';
import Styles from './RequestIntroForm.module.scss';

const DEPENDENT_FIELD_MISSING_ERROR_MESSAGE = 'You have used variables, but the receivers list is empty';
const INVALID_TEMPLATE_ERROR_MESSAGE = 'Invalid variable used in this template';
const REQUIRED_RECEIVER_ERROR_MESSAGE = 'Please select at least one receiver';

export const RequestIntroForm = ({
  connectors,
  sender,
  senderEmail,
  teamName,
  profileFullName,
  profileLinkedin,
  profileId,
  profilePipelines,
  close,
  showPreview,
  pipelineId: initialPipelineId = '',
  onSubmitRequestWithPipelineSuccess,
}: RequestIntroFormProps) => {
  type MessageVariable = keyof Partial<ReturnType<typeof getMessageVariables>>;
  const {id: currentTeamId} = useCurrentTeam();
  const {data, refetch: refetchPipelines} = useQueryPipelines(currentTeamId, {
    limit: 100,
    offset: 0,
  });
  const pipelines = useMemo(() => data?.items || [], [data]);

  const pipelinesOptions = pipelines
    .filter(({pipeline}) => profilePipelines.includes(pipeline.id))
    .map(({pipeline}) => ({
      label: pipeline.title,
      value: pipeline.id,
    }));

  const requestIntroInitialValues: RequestIntroFormType = {
    receivers: [connectors.map(({user}) => user.id)[0]],
    addToCc: false,
    subject: '{first_name} intro to {profile_full_name}?',
    pipeline: initialPipelineId || '',
    message: getDefaultMessage(Boolean(initialPipelineId)),
    includeForwardable: Boolean(initialPipelineId),
  };

  const {
    formState: {isSubmitting, errors},
    register,
    control,
    handleSubmit,
    getValues,
    watch,
    trigger,
  } = useForm({
    defaultValues: requestIntroInitialValues,
  });

  const pipelineId = watch('pipeline');
  const receivers = watch('receivers');

  const selectedPipeline: PipelineCommonResponse | null = useMemo(
    () => (pipelineId && pipelines.find(item => item.pipeline.id === pipelineId)?.pipeline) || null,
    [pipelineId, pipelines]
  );

  const getMessageVariables = useCallback(
    (receiver: string, withlinks?: boolean) => {
      const connector = connectors.find(connector => connector.user.id === receiver)?.user;

      const linkedProfileFullName = profileLinkedin
        ? `<a href="${addHttpsToUrl(profileLinkedin)}" target="_blank">${profileFullName}</a>`
        : profileFullName;

      return {
        ...(connector && {
          first_name: connector.firstName,
          last_name: connector.lastName,
        }),
        team_name: teamName,
        profile_full_name: withlinks ? linkedProfileFullName : profileFullName,
        ...(pipelinesOptions.length > 0 && {
          list_title: pipelinesOptions.find(({value}) => value === pipelineId)?.label,
        }),
        sender_full_name: sender,
        sender_email: withlinks ? `<a href="mailto:${senderEmail}">${senderEmail}</a>` : senderEmail,
      } as const;
    },
    [
      connectors,
      teamName,
      profileLinkedin,
      profileFullName,
      pipelinesOptions,
      pipelineId,
      sender,
      senderEmail,
    ]
  );

  const validateReceiversDependentField = useCallback(
    (fieldValue: string) => {
      const receiversDependentVariables: MessageVariable[] = ['first_name', 'last_name'];
      const isDependentVariableUsed = receiversDependentVariables.some(variable =>
        isVariableUsedInTemplate(fieldValue, variable)
      );

      if (isDependentVariableUsed && receivers.length === 0) {
        return DEPENDENT_FIELD_MISSING_ERROR_MESSAGE;
      }

      const isTemplateValid = verifyTemplate({
        templateString: fieldValue,
        vars: getMessageVariables(receivers[0]),
      });
      if (isDependentVariableUsed && !isTemplateValid) {
        return INVALID_TEMPLATE_ERROR_MESSAGE;
      }

      return true;
    },
    [getMessageVariables, receivers]
  );

  const {writeToLog, isInLog} = useRequestIntroLog();

  const submitRequest = useCallback(
    (
      receiverId: string,
      {subject, message, addToCc, pipeline: pipelineId, includeForwardable}: RequestIntroFormType
    ) =>
      new Promise(async (resolve, reject) => {
        const subjectTemplateVars = getMessageVariables(receiverId, false);
        const bodyTemplateVars = getMessageVariables(receiverId, true);
        const receiverFullName = `${bodyTemplateVars.first_name} ${bodyTemplateVars.last_name}`;

        const introBody = getIntroBody(
          message,
          bodyTemplateVars,
          includeForwardable,
          selectedPipeline?.forwardableTemplate
        );

        requestIntro({
          profileId,
          to: receiverId,
          subject: fillTemplate({templateString: subject, vars: subjectTemplateVars}),
          body: linkify(introBody),
          addToCc,
        })
          .then(() => {
            notify(`Request sent to ${receiverFullName}.`);
            writeToLog(profileId, receiverId, pipelineId);
            if (pipelineId) {
              updateStatus(pipelineId, profileId, {status: PipelineStatus.introRequested}).then(() =>
                onSubmitRequestWithPipelineSuccess?.(pipelineId)
              );
            }
            resolve(receiverId);
          })
          .catch(() => {
            notify(<>Something went wrong when sending request to&nbsp;{receiverFullName}.</>);
            reject();
          });
      }),
    [
      getMessageVariables,
      selectedPipeline?.forwardableTemplate,
      profileId,
      writeToLog,
      onSubmitRequestWithPipelineSuccess,
    ]
  );

  const onSubmit = useCallback(
    async (formState: RequestIntroFormType) => {
      const recentRequests = receivers.some(receiver => isInLog(profileId, receiver, formState.pipeline));
      if (recentRequests) {
        notify(
          'You have already sent a similar request in last\n24 hours. Please change receivers or pipeline.'
        );
        return;
      }
      const submitQueue = await Promise.allSettled(
        formState.receivers.map(receiver => submitRequest(receiver, formState))
      );

      segmentTrack('Form Submitted', {
        form: 'intro request',
        recipients_ids: formState.receivers,
        profile_id: profileId,
        ...(formState.pipeline && {pipeline_id: formState.pipeline}),
      });

      const hasErrorsOccurred = submitQueue.filter(({status}) => status === 'rejected').length > 0;
      if (!hasErrorsOccurred) {
        close();
        localStorage.setItem(DEFAULT_REQUEST_INTRO_MESSAGE_KEY, formState.message);
      }
    },
    [receivers, profileId, isInLog, submitRequest, close]
  );

  const connectorsOptions = connectors.map(({user: {firstName, lastName, id}, score}) => ({
    value: id,
    template: (
      <div className="flex flex-row">
        <AvatarWithFullname fullname={`${firstName} ${lastName}`} />
        <PermissionChecker permission={Permission.ConnectionStrength}>
          {' '}
          <Score value={score} withTooltip />
        </PermissionChecker>
      </div>
    ),
  }));

  const handlePreviewData = useCallback(() => {
    const {subject, message, addToCc, pipeline, includeForwardable} = getValues();
    const subjectTemplateVars = getMessageVariables(receivers[0], false);
    const bodyTemplateVars = getMessageVariables(receivers[0], true);
    const introBody = getIntroBody(
      message,
      bodyTemplateVars,
      includeForwardable,
      selectedPipeline?.forwardableTemplate
    );
    return {
      subject: fillTemplate({templateString: subject, vars: subjectTemplateVars}),
      message: linkify(introBody),
      addToCc,
      includeForwardable,
      pipeline,
      receivers: connectors
        .filter(({user}) => receivers.includes(user.id))
        .map(({user: {firstName, lastName}}) => `${firstName} ${lastName}`),
    };
  }, [getValues, getMessageVariables, receivers, connectors, selectedPipeline]);

  const templateVariablesAutocompletions = Object.keys(getMessageVariables(connectors[0].user.id));

  return (
    <FormWrapper
      cancelButton={{label: 'Cancel', onClick: close}}
      actionButton={{
        label: 'Send now',
        onClick: handleSubmit(onSubmit),
        loading: isSubmitting,
      }}
      additionalButton={<Button onClick={() => showPreview(handlePreviewData())}>Preview</Button>}
      fullWidth
      onModal
    >
      <FieldRow label="From">
        <Input
          className={Styles.requestIntroInput}
          disabled
          type="text"
          defaultValue={`${sender} via The Swarm`}
        />
      </FieldRow>
      <FieldRow label="To">
        <Controller
          control={control}
          name="receivers"
          render={({field: {onChange}}) => {
            const handleOnChange = async (receivers: string[]) => {
              onChange(receivers);
              await trigger();
            };
            return (
              <>
                <Multiselect
                  options={connectorsOptions}
                  onChange={handleOnChange}
                  isInvalid={Boolean(errors.receivers)}
                  message={errors.receivers?.message}
                  initialValues={receivers}
                />
                <Message
                  message="The message will be sent separately to each recipient."
                  className={cs(Styles.receiversInfo, {['hidden']: receivers.length < 2})}
                />
              </>
            );
          }}
          rules={{
            validate: value => value.length > 0 || REQUIRED_RECEIVER_ERROR_MESSAGE,
          }}
        />
      </FieldRow>
      <FieldRow label="CC">
        <label>
          <input {...register('addToCc')} type="checkbox" tabIndex={-1} /> {senderEmail}
        </label>
      </FieldRow>
      <FieldRow label="Subject">
        <Controller
          control={control}
          name="subject"
          render={({field: {onChange, value}}) => {
            const handleOnChange = async (subject: string) => {
              onChange(subject);
              await trigger();
            };
            return (
              <TemplateEditor
                onChange={handleOnChange}
                value={value}
                autocompletions={templateVariablesAutocompletions}
                placeholder="Type Subject..."
                isInvalid={Boolean(errors.subject)}
                message={errors.subject?.message}
                singleLine
              />
            );
          }}
          rules={{
            validate: validateReceiversDependentField,
          }}
        />
      </FieldRow>
      {pipelinesOptions.length > 0 && (
        <FieldRow label="List">
          <Controller
            control={control}
            name="pipeline"
            render={({field: {onChange, value}}) => (
              <Multiselect
                singleOption
                options={pipelinesOptions}
                onChange={onChange}
                {...(value && {initialValues: [value]})}
              />
            )}
          />
        </FieldRow>
      )}
      <div className={Styles.customMessage}>
        <p className={Styles.variablesInstruction} data-intercom-target="variables-values">
          Write custom message, use <Pill>/</Pill> symbol to insert variables values.
        </p>
        <Controller
          control={control}
          name="message"
          render={({field: {onChange, value}}) => {
            const handleOnChange = async (subject: string) => {
              onChange(subject);
              await trigger();
            };
            return (
              <TemplateEditor
                onChange={handleOnChange}
                value={value}
                autocompletions={templateVariablesAutocompletions}
                placeholder="Type your message..."
                isInvalid={Boolean(errors.message)}
                message={errors.message?.message}
              />
            );
          }}
          rules={{
            required: 'Please enter message template',
            validate: validateReceiversDependentField,
          }}
        />
      </div>
      {selectedPipeline && (
        <>
          <label className={Styles.forwardableCheckbox}>
            <input {...register('includeForwardable')} type="checkbox" /> Include list's forwardable intro
            template
          </label>
          <Forwardable pipeline={selectedPipeline} refetchPipelines={refetchPipelines} />
        </>
      )}
    </FormWrapper>
  );
};
