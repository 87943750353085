import {Button} from '../button';
import {useSwitcherContext} from './SwitcherContext';

export type SwitcherItemProps = {
  value: string | undefined;
  children: string;
  disabled?: boolean;
};

export const SwitcherItem = ({value, children: label, disabled}: SwitcherItemProps) => {
  const {selectedValue, setValue} = useSwitcherContext();

  const isActive = selectedValue === value;

  return (
    <Button
      key={value}
      onClick={() => setValue(value)}
      size="sm"
      variant={isActive ? 'black' : 'tertiary'}
      outline={!isActive}
      disableTitleCase
      intercomTarget={`switcher-${label.replace(/\s+/g, '-').toLowerCase()}`}
      disabled={disabled}
    >
      {label}
    </Button>
  );
};
