import {ProfilesBffColumns} from '../../../defs';

export const PIPELINES_SAVED_SEARCH_PREFERENCES_KEY = 'list_pipelines_saved_search_table_columns';

export const availableColumns = [
  ProfilesBffColumns.FullName,
  ProfilesBffColumns.AddToPipeline,
  ProfilesBffColumns.JobTitle,
  ProfilesBffColumns.JobCompanyName,
  ProfilesBffColumns.LocationName,
  ProfilesBffColumns.Tags,
  ProfilesBffColumns.ConnectionsStrength,
  ProfilesBffColumns.ConnectedMembersCount,
];
