import {Dialog} from '@headlessui/react';
import {useCallback, useState} from 'react';
import {FiBookOpen as BookOpenIcon} from 'react-icons/fi';
import {segmentTrack} from '../../../../../utils';
import {Button} from '../../../../button';
import {Editor} from './Editor';
import {ModalContentProps} from './types';

type PossibleButtonClickedEventLabels = 'learn more' | 'cancel' | 'apply';
const emitButtonClicked = (label: PossibleButtonClickedEventLabels, additionalPayload: object = {}) =>
  segmentTrack('Button Clicked', {label, location: 'list filter', ...additionalPayload});

export const ModalContent = ({
  value,
  modalTitle,
  modalDescription,
  tokensLabel,
  onApply,
  onCancel,
  minItemLength,
}: ModalContentProps) => {
  const [tokens, setTokens] = useState(value);

  const onApplyClicked = useCallback(() => {
    onApply(tokens);
    emitButtonClicked('apply', {item_count: tokens.length});
  }, [onApply, tokens]);

  const onCancelClicked = useCallback(() => {
    onCancel();
    emitButtonClicked('cancel');
  }, [onCancel]);

  return (
    <>
      <Dialog.Title as="h3" className="text-3xl font-bold text-swarm-black">
        {modalTitle}
      </Dialog.Title>
      {modalDescription && <Dialog.Description className="mb-4 mt-2">{modalDescription}</Dialog.Description>}
      <Editor tokens={tokens} onChange={setTokens} tokensLabel={tokensLabel} minItemLength={minItemLength} />

      <div className="mt-8 flex justify-between">
        <div>
          <Button
            variant="tertiary"
            icon={<BookOpenIcon />}
            href="https://help.theswarm.com/en/articles/6863913-how-to-search-through-the-network-graph"
            target="_blank"
            onClick={() => emitButtonClicked('learn more')}
          >
            Learn more about advanced filtering
          </Button>
        </div>
        <div className="flex gap-2">
          <Button onClick={onCancelClicked}>Cancel</Button>
          <Button variant="primary" onClick={onApplyClicked}>
            Apply
          </Button>
        </div>
      </div>
    </>
  );
};
