export enum ConnectionsColumns {
  LastName = 'last_name',
  Status = 'pipeline_status',
  AddToPipeline = 'add_to_pipeline',
  ConnectionsStrength = 'connections_strength',
  JobTitle = 'job_title',
  JobCompanyName = 'job_company_name',
  LocationName = 'location_name',
  StartDate = 'experience_start_date',
  EndDate = 'experience_end_date',
  Tags = 'tags',
  ConnectedMembersCount = 'connected_members_count',
}

export const connectionsColumnsLabelsMap = {
  [ConnectionsColumns.LastName]: 'Name',
  [ConnectionsColumns.AddToPipeline]: 'Add',
  [ConnectionsColumns.Status]: 'Status',
  [ConnectionsColumns.ConnectionsStrength]: 'Strength',
  [ConnectionsColumns.JobTitle]: 'Title',
  [ConnectionsColumns.JobCompanyName]: 'Company',
  [ConnectionsColumns.LocationName]: 'Location',
  [ConnectionsColumns.StartDate]: 'Start Date',
  [ConnectionsColumns.EndDate]: 'End Date',
  [ConnectionsColumns.Tags]: 'Profile Tags',
  [ConnectionsColumns.ConnectedMembersCount]: 'Connected To',
};
