import cs from 'classnames';
import {ReactNode} from 'react';
import Styles from './PageHeader.module.scss';

type Props = {
  title?: string;
  rightSlot?: ReactNode;
  hasBottomSeparator?: boolean;
  className?: string;
  children?: ReactNode;
  gray?: boolean; // this will be a general style when Global Search is introduced
};

export const PageHeader = ({title, rightSlot, hasBottomSeparator, className, children, gray}: Props) => {
  return (
    <div
      className={cs(
        Styles.pageHeader,
        {[Styles.borderedPageHeader]: hasBottomSeparator},
        {[Styles.grayPageHeader]: gray},
        className
      )}
    >
      <div className={Styles.leftSlot}>
        {title && <h2 className={Styles.title}>{title}</h2>}
        {children && <div className={Styles.childrenWrapper}>{children}</div>}
      </div>
      {rightSlot && <div className={Styles.rightSlot}>{rightSlot}</div>}
    </div>
  );
};
