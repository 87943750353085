import {Dialog} from '@headlessui/react';
import {useCallback, useState} from 'react';
import {sleep} from '../../../utils';
import {LoadingSpinner} from '../../animations';
import {CompanyMapSuccess} from '../success/CompanyMapSuccess';
import {ImportConnectionsMapCompanyForm} from './ImportConnectionsMapCompanyForm';

enum MapCompanyViews {
  Waiting,
  Form,
  Success,
}

type Props = {
  onClose: () => unknown;
};

export const ImportConnectionsMapCompany = ({onClose}: Props) => {
  const [currentView, setCurrentView] = useState<MapCompanyViews>(MapCompanyViews.Form);

  const handleSuccess = useCallback(() => {
    setCurrentView(MapCompanyViews.Waiting);
    sleep(2000).then(() => {
      setCurrentView(MapCompanyViews.Success);
    });
  }, []);

  switch (currentView) {
    case MapCompanyViews.Form:
      return <ImportConnectionsMapCompanyForm onClose={onClose} onSuccess={handleSuccess} />;
    case MapCompanyViews.Success:
      return <CompanyMapSuccess onClose={onClose} />;
    case MapCompanyViews.Waiting:
      return <WaitingScreen />;
    default:
      ((_: never) => {})(currentView);
      return null;
  }
};

const WaitingScreen = () => {
  return (
    <>
      <Dialog.Title as="h3" className="text-3xl font-bold text-swarm-black">
        AI Network Mapper
      </Dialog.Title>
      <Dialog.Description className="mb-4 mt-2 font-normal">Processing... please wait</Dialog.Description>
      <div className="my-8 grid justify-items-center">
        <LoadingSpinner size="large" />
      </div>
    </>
  );
};
