import {Helmet} from 'react-helmet';
import {Card} from '../../components/card';
import {PageHeader} from '../../components/page-header';
import {Pill} from '../../components/pill';
import {TabLinks, TabPageContent} from '../../components/tabs';
import {AppLayout} from '../../layouts';
import {Integrations} from './integrations';
import {useTabsConfig} from './useTabsConfig';

export const IntegrationsPage = () => {
  const tabs = useTabsConfig();
  return (
    <>
      <Helmet>
        <title>Integrations - The Swarm</title>
      </Helmet>
      <AppLayout
        header={<PageHeader title="Team Settings" />}
        subHeader={<TabLinks tabs={tabs} activeTab="Integrations" />}
      >
        <div className="mx-auto max-w-4xl">
          <Card color="yellow" className="relative mt-6">
            <Pill color="yellow" className="absolute -top-2 left-4 ">
              Beta
            </Pill>{' '}
            <p className="text-sm text-swarm-gray-900">
              This feature is in development. Please contact us at{' '}
              <a href="mailto:hello@theswarm.com" className="hover:underline">
                hello@theswarm.com
              </a>{' '}
              so we can assist with your implementation.
            </p>
          </Card>
        </div>
        <TabPageContent
          heading="Available integrations"
          subheading="Connect with the tools your team uses every day."
        >
          <Integrations />
        </TabPageContent>
      </AppLayout>
    </>
  );
};
