import {Helmet} from 'react-helmet';
import {PageHeader} from '../../components/page-header';
import {TabLinks, TabPageContent} from '../../components/tabs';
import {AppLayout} from '../../layouts';
import {TagsManagement} from './tags';
import {useTabsConfig} from './useTabsConfig';

export const TagsPage = () => {
  const tabs = useTabsConfig();

  return (
    <>
      <Helmet>
        <title>Tags - The Swarm</title>
      </Helmet>
      <AppLayout
        header={<PageHeader title="Team Settings" />}
        subHeader={<TabLinks tabs={tabs} activeTab="Tags" />}
      >
        <TabPageContent>
          <TagsManagement />
        </TabPageContent>
      </AppLayout>
    </>
  );
};
