import {ConnectionsColumns} from '../../../defs';

export const PREFERENCES_KEY = 'company_profiles_columns';
export const availableColumns = [
  ConnectionsColumns.LastName,
  ConnectionsColumns.JobTitle,
  ConnectionsColumns.LocationName,
  ConnectionsColumns.StartDate,
  ConnectionsColumns.EndDate,
  ConnectionsColumns.ConnectionsStrength,
  ConnectionsColumns.ConnectedMembersCount,
];
