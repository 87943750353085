import {camelCase} from 'lodash';
import {
  FilterKey,
  FilterOperator,
  FiltersState,
  FilterState,
  FilterValue,
} from '../../../components/new-filters/types';
import {ensureIsArray} from '../../../utils';

const oldFilterKeysToNewFilterKeysMap: Record<string, string> = {
  location_name: 'location',
  connections_strength: 'connectionStrength',
  current_job_company_location_name: 'currentJobCompanyLocation',
  tags: 'tag',
  crms: 'crm',
  company_list: 'currentJobCompanyNameList',
};

const unwrapQuestionMarks = (val: string[]) => val.map(str => str.replace(/^"(.*)"$/, '$1'));

export const translateSavedSearchFilters = (
  savedSearch: string,
  availableFilters: FilterKey[]
): FiltersState => {
  try {
    const savedSearchObject = JSON.parse(savedSearch);
    return <FiltersState>Object.entries(savedSearchObject).reduce((acc, [key, value]) => {
      const filterKey = Object.keys(oldFilterKeysToNewFilterKeysMap).includes(key)
        ? oldFilterKeysToNewFilterKeysMap[key]
        : camelCase(key);
      const values = ensureIsArray(value);
      return availableFilters.includes(filterKey)
        ? {
            ...acc,
            [filterKey]: {
              operator: FilterOperator.isOneOf,
              value: unwrapQuestionMarks(values as FilterValue),
              selectedOptions: values.map(val => ({value: val, label: val})),
            } satisfies FilterState,
          }
        : acc;
    }, {});
  } catch {
    return {};
  }
};
