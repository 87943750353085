import {ReactNode} from 'react';
import {ActionPermission, FilterOptionData} from '../../types';
import {FilterListTexts} from './filter/filter-kinds/list/types';

export enum FilterKind {
  ENUM = 'enum',
  STRING = 'string',
  LIST = 'list',
}

export type DecoratorType = 'user' | 'strength' | 'crm' | 'tag';

export enum FilterOperator {
  is = 'is',
  isNot = 'is not',
  isOneOf = 'is one of',
  isNoneOf = 'is none of',
}

export type FilterKey = string;
type CategoryKey = string;

export type FilterValue = string[];

type OptionType = {
  value: string;
  label: string;
  count?: number;
  data?: FilterOptionData;
};

export type FilterOptions = OptionType[];

export type FilterType = {
  key: FilterKey;
  title: string;
  icon?: () => ReactNode;
  decorator?: DecoratorType;
  category: CategoryKey;
  kind: FilterKind;
  permission?: ActionPermission;
  searchable?: boolean;
  options?: FilterOptions;
} & (FilterTypeList | FilterTypeOther);
type FilterTypeList = FilterListTexts & {
  kind: FilterKind.LIST;
};

type FilterTypeOther = {
  kind: FilterKind.ENUM | FilterKind.STRING;
};

export type FiltersConfig = {
  filtersName: string;
  categories: {
    key: string;
    title: string;
  }[];
  filters: Record<FilterKey, FilterType>;
  availableFilters: FilterKey[];
};

export type FilterState = {
  value: FilterValue;
  operator: FilterOperator;
  selectedOptions?: FilterOptions;
};

export type FiltersState = {
  [key in FilterKey]: FilterState;
};
