import {FiMapPin as LocationIcon, FiTag as TagIcon} from 'react-icons/fi';
import {
  PiFactory as IndustryIcon,
  PiList as ListOfCompaniesIcon,
  PiTextT as CompanyNameIcon,
  PiUsersThree as SizeIcon,
} from 'react-icons/pi';
import {TbTransfer as CRMIcon} from 'react-icons/tb';
import {FilterKind, FiltersConfig} from '../../components/new-filters/types';

export const filtersConfig: FiltersConfig = {
  filtersName: 'companiesFilters',
  categories: [
    {
      key: 'companiesFilters',
      title: 'Companies Filters',
    },
    {
      key: 'generalData',
      title: 'General Data',
    },
    {
      key: 'integrationsFilters',
      title: 'Integrations Filters',
    },
  ],
  filters: {
    name: {
      key: 'name',
      title: 'Company Name',
      icon: () => <CompanyNameIcon />,
      category: 'companiesFilters',
      kind: FilterKind.ENUM,
    },
    nameList: {
      key: 'nameList',
      title: 'List of Companies',
      icon: () => <ListOfCompaniesIcon />,
      category: 'companiesFilters',
      kind: FilterKind.LIST,

      modalTitle: 'List of Companies',
      modalDescription: 'Enter company names below (separated by commas or line breaks)',
      tokensLabel: 'Recognized company names',
    },
    size: {
      key: 'size',
      title: 'Company Size',
      icon: () => <SizeIcon />,
      category: 'companiesFilters',
      kind: FilterKind.ENUM,
    },
    industry: {
      key: 'industry',
      title: 'Company Industry',
      icon: () => <IndustryIcon />,
      category: 'companiesFilters',
      kind: FilterKind.ENUM,
    },
    location: {
      key: 'location',
      title: 'Company Headquaters',
      icon: () => <LocationIcon />,
      category: 'companiesFilters',
      kind: FilterKind.ENUM,
    },
    tag: {
      key: 'tag',
      title: 'Company Tags',
      icon: () => <TagIcon />,
      category: 'companiesFilters',
      kind: FilterKind.ENUM,
      decorator: 'tag',
    },
    crm: {
      key: 'crm',
      title: 'CRM Source',
      icon: () => <CRMIcon />,
      category: 'integrationsFilters',
      kind: FilterKind.ENUM,
      decorator: 'crm',
      searchable: false,
    },
  },
  availableFilters: ['name', 'nameList', 'industry', 'size', 'location', 'tag', 'crm'],
};
