import {createContext, ReactNode, useContext} from 'react';

type ContextValue = {
  selectedValue: string | undefined;
  setValue: (value: string | undefined) => void;
};

const SwitcherContext = createContext<ContextValue>({
  selectedValue: undefined,
  setValue: () => {},
});

type Props = ContextValue & {
  children: ReactNode;
};

export const SwitcherContextProvider = ({selectedValue, setValue, children}: Props) => {
  return <SwitcherContext.Provider value={{selectedValue, setValue}}>{children}</SwitcherContext.Provider>;
};

export const useSwitcherContext = () => useContext(SwitcherContext);
