import {ProfilesBffColumns} from '../../../defs';

export const PROFILES_IN_PIPELINE_PREFERENCES_KEY = 'list_profiles_in_pipeline_table_columns';

export const availableColumns = [
  ProfilesBffColumns.FullName,
  ProfilesBffColumns.Status,
  ProfilesBffColumns.JobTitle,
  ProfilesBffColumns.JobCompanyName,
  ProfilesBffColumns.LocationName,
  ProfilesBffColumns.Tags,
  ProfilesBffColumns.ConnectionsStrength,
  ProfilesBffColumns.ConnectedMembersCount,
];
