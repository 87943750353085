import {useCallback, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {FiUser as UserIcon} from 'react-icons/fi';
import {useMutationUpdateFirstLastName} from '../../../../api/user';
import {AuthBox} from '../../../../components/auth';
import {AuthBoxIcon} from '../../../../components/auth/auth-box/AuthBoxIcon';
import {Button} from '../../../../components/button';
import {Input, Label} from '../../../../components/form';
import {useOnboardingContext} from '../../../../contexts';
import {useCurrentUser} from '../../../../hooks';
import {ErrorResponse} from '../../../../types';
import {segmentVirtualPageview} from '../../../../utils';

const HeaderImage = () => (
  <AuthBoxIcon>
    <UserIcon size={32} />
  </AuthBoxIcon>
);

type FirstLastNameFormState = {
  firstName: string;
  lastName: string;
};

export const FirstLastNameStep = () => {
  const {id: userId, firstName: currentFirstName, lastName: currentLastName} = useCurrentUser();
  const {onFinishStep} = useOnboardingContext();

  useEffect(() => {
    segmentVirtualPageview('/create-profile');
  }, []);

  const {
    formState: {isSubmitting, errors},
    register,
    handleSubmit,
    setError,
  } = useForm<FirstLastNameFormState>({
    defaultValues: {firstName: currentFirstName, lastName: currentLastName},
  });

  const onError = useCallback(
    (e: ErrorResponse | undefined) => {
      if (!e?.errors?.length) {
        setError('root', {message: 'Something went wrong. Please try again later.'});
        return;
      }
    },
    [setError]
  );

  const mutationUpdateFirstLastName = useMutationUpdateFirstLastName(userId, {
    onSuccess: onFinishStep,
    onError: e => onError(e.response?.data),
  });

  const onSubmit = useCallback(
    ({firstName, lastName}: FirstLastNameFormState) => {
      mutationUpdateFirstLastName.mutate({
        firstName,
        lastName,
      });
    },

    [mutationUpdateFirstLastName]
  );

  return (
    <AuthBox headerImage={<HeaderImage />} headerTitle="Tell us about yourself">
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-2 text-left">
        <div className="flex flex-row gap-2">
          <div className="grow">
            <Label htmlFor="signup-firstname">First name</Label>
            <Input
              {...register('firstName', {required: 'First name is required'})}
              type="text"
              placeholder="Enter your first name"
              inputSize="lg"
              autoFocus
              isInvalid={Boolean(errors.firstName)}
              message={errors.firstName?.message}
            />
          </div>

          <div className="grow">
            <Label htmlFor="signup-lastname">Last name</Label>
            <Input
              {...register('lastName', {required: 'Last name is required'})}
              placeholder="Enter your last name"
              type="text"
              inputSize="lg"
              isInvalid={Boolean(errors.lastName)}
              message={errors.lastName?.message}
            />
          </div>
        </div>

        <Button
          type="submit"
          variant="primary"
          fullWidth
          loading={isSubmitting}
          size="lg"
          tracking={{
            label: 'continue',
            location: 'create profile',
          }}
        >
          Continue
        </Button>
      </form>
    </AuthBox>
  );
};
