import cs from 'classnames';
import {Briefcase as BriefcaseIcon} from 'phosphor-react';
import {MouseEvent as ReactMouseEvent, useCallback} from 'react';
import {FieldError, UseFormRegister, UseFormSetError, UseFormTrigger, UseFormWatch} from 'react-hook-form';
import {checkTeamNameUnique} from '../../../../../../../api';
import {AuthBox, AuthBoxIcon} from '../../../../../../../components/auth';
import {Button} from '../../../../../../../components/button';
import {Input} from '../../../../../../../components/form';
import {CreateTeamFormState} from '../ManuallyCreateTeam';

type Props = {
  register: UseFormRegister<CreateTeamFormState>;
  error?: FieldError;
  onContinue: () => void;
  trigger: UseFormTrigger<CreateTeamFormState>;
  watch: UseFormWatch<CreateTeamFormState>;
  setError: UseFormSetError<CreateTeamFormState>;
};

export const TeamName = ({register, error, onContinue, trigger, watch, setError}: Props) => {
  const teamName = watch('teamName');

  const checkName = useCallback(
    async (name: string) => {
      try {
        return await checkTeamNameUnique(name).then(() => true);
      } catch (error) {
        setError('teamName', {
          type: 'manual',
          message:
            'Team name already in use, contact support@theswarm.com if you want to join an existing team.',
        });
        return false;
      }
    },
    [setError]
  );

  const onClickNext = useCallback(
    async (e: ReactMouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>) => {
      e.preventDefault(); // prevent form submission

      const fieldValid = await trigger('teamName');
      const nameUnique = await checkName(teamName);

      fieldValid && nameUnique && onContinue();
    },
    [checkName, onContinue, trigger, teamName]
  );

  return (
    <AuthBox headerTitle="Name Your Team" headerImage={<HeaderImage />}>
      <div className="mb-1 text-left">
        <p className="-mb-1 text-left text-sm font-semibold">Team name</p>
        <Input
          {...register('teamName', {
            required: 'Please enter a team name',
            minLength: {value: 2, message: 'Team name must be at least 2 characters long'},
          })}
          type="text"
          placeholder="Enter your team name"
          isInvalid={Boolean(error)}
          message={error?.message}
          className={cs({['-mb-1']: error})}
        />
      </div>
      <Button fullWidth variant="primary" onClick={onClickNext} size="lg">
        Next
      </Button>
    </AuthBox>
  );
};

const HeaderImage = () => (
  <AuthBoxIcon>
    <BriefcaseIcon size={32} />
  </AuthBoxIcon>
);
