export type Onboarding = {
  hasFirstLastName: boolean;
  linkedinUrl: string;
  linkedinSuggestions: string[] | null;
  hasTeam: boolean;
  hasPendingTeamInvitations: boolean;
  hasSkippedLinkedin: boolean;
  hasSkippedCreatingSharedNetwork: boolean;
  shouldInviteMembers: boolean;
};

export enum OnboardingStep {
  Loading = 'Loading',
  FirstLastName = 'FirstLastName',
  WorkExperience = 'WorkExperience',
  TeamWithInvitations = 'TeamWithInvitations',
  TeamWithoutInvitations = 'TeamWithoutInvitations',
  InviteMembersManually = 'InviteMembersManually',
}

export type OnboardingStepRule = {
  condition: boolean;
  step: OnboardingStep;
};

export type OnboardingCompany = {
  logoURL: string;
  name: string;
  id: string;
  url: string;
  mappable: boolean;
  liUrl: string;
};

export enum CompanyStatus {
  NotStared,
  Loading,
  NotFound,
  CompanyCantBeMapped,
  OK,
}
