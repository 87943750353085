import {useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {checkTeamNameUnique, useMutationUpdateTeam} from '../../../api';
import {Avatar} from '../../../components/avatar';
import {Button} from '../../../components/button';
import {Input, Label} from '../../../components/form';
import {notify} from '../../../components/notifications';
import {useCurrentTeam, useCurrentUserSession} from '../../../hooks';
import Styles from './TeamProfile.module.scss';

type FormProps = {
  name: string;
};

export const TeamProfile = () => {
  const {refresh} = useCurrentUserSession();
  const {name: currentTeamName, id: teamId} = useCurrentTeam();

  const {
    formState: {isSubmitting, errors},
    register,
    handleSubmit,
    setError,
    watch,
  } = useForm<FormProps>({
    defaultValues: {name: currentTeamName},
  });

  const mutationUpdateTeam = useMutationUpdateTeam(teamId, {
    onSuccess: () => {
      refresh().then(() => {
        notify('Team updated successfully');
      });
    },
    onError: () => {
      setError('root', {message: 'Something went wrong. Please try again later.'});
    },
  });

  const onSubmit = useCallback(
    async ({name}: FormProps) => {
      try {
        return await checkTeamNameUnique(name).then(() => {
          mutationUpdateTeam.mutate({name});
          return true;
        });
      } catch (error) {
        setError('name', {
          type: 'manual',
          message: 'Team name already in use.',
        });
        return false;
      }
    },

    [mutationUpdateTeam, setError]
  );
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={Styles.accountSettings}>
          <Avatar size="xxl" fullname={currentTeamName} />
          <div className={Styles.formWrapper}>
            <div className={Styles.editTeamName}>
              <div className={Styles.editTeamNameField}>
                <Label>Team name</Label>
                <Input
                  {...register('name', {required: 'Team name is required'})}
                  type="text"
                  placeholder="Enter your first name"
                  isInvalid={Boolean(errors.name)}
                  message={errors.name?.message}
                  messageClassName={Styles.errorMessage}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.footer}>
          <Button
            type="submit"
            variant="primary"
            loading={isSubmitting}
            tracking={{
              label: 'edit team name',
            }}
            disabled={currentTeamName === watch().name}
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};
