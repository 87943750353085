export enum ProfilesBffColumns {
  FullName = 'lastName',
  AddToPipeline = 'addToPipeline',
  Status = 'pipelineStatus',
  ConnectionsStrength = 'connectionStrength',
  JobTitle = 'jobTitle',
  JobCompanyName = 'jobCompanyName',
  LocationName = 'location_name',
  StartDate = 'experienceStartDate',
  EndDate = 'experienceEndDate',
  Tags = 'tags',
  ConnectedMembersCount = 'connectedMembersCount',
}

export const profilesBffLabelsMap = {
  [ProfilesBffColumns.FullName]: 'Name',
  [ProfilesBffColumns.AddToPipeline]: 'Add',
  [ProfilesBffColumns.Status]: 'Status',
  [ProfilesBffColumns.ConnectionsStrength]: 'Strength',
  [ProfilesBffColumns.JobTitle]: 'Title',
  [ProfilesBffColumns.JobCompanyName]: 'Company',
  [ProfilesBffColumns.LocationName]: 'Location',
  [ProfilesBffColumns.StartDate]: 'Start Date',
  [ProfilesBffColumns.EndDate]: 'End Date',
  [ProfilesBffColumns.Tags]: 'Profile Tags',
  [ProfilesBffColumns.ConnectedMembersCount]: 'Connected To',
};
