import {useCallback} from 'react';
import {FiUserPlus as AddPersonIcon} from 'react-icons/fi';
import {Button} from '../../../components/button';
import {useImportConnectionsModal} from '../../../contexts';
import {segmentTrack} from '../../../utils';

export const HeaderButtons = () => {
  const {showImportModal} = useImportConnectionsModal();

  const handleAddConnectionsClick = useCallback(() => {
    showImportModal();

    segmentTrack('Button Clicked', {
      label: 'add connections',
      location: 'network graph top bar',
    });
  }, [showImportModal]);

  return (
    <Button
      icon={<AddPersonIcon />}
      onClick={handleAddConnectionsClick}
      variant="primary"
      data-intercom-target="Add connections"
    >
      Add connections
    </Button>
  );
};
