import {useCallback, useEffect, useState} from 'react';
import {segmentTrack} from '../../utils';
import {Modal} from '../modal';
import {trackStrategySelection} from './analytics.utils';
import {ImportConnectionsChromeExtension} from './chrome-extension';
import {ImportConnectionsGoogle} from './google';
import {ImportConnectionsCSV} from './linkedin-csv';
import {ImportConnectionsIntro} from './main';
import {ImportConnectionsMapCompany} from './map-company';
import {ImportConnectionsWorkOverlaps} from './overlaps';
import {ImportConnectionsSuccess} from './success/ImportConnectionsSuccess';

type Props = {
  visible: boolean;
  onClose: () => unknown;
  initialView?: ImportViews;
};

type ContentProps = Pick<Props, 'initialView' | 'onClose'>;

export enum ImportViews {
  Intro = 'Intro',
  WorkOverlaps = 'WorkOverlaps',
  CompanyMap = 'CompanyMap',
  ManualImport = 'ManualImport',
  ChromeExtension = 'ChromeExtension',
  GoogleImport = 'GoogleImport',
  Success = 'Success',
}

const Content = ({initialView = ImportViews.Intro, onClose}: ContentProps) => {
  const [currentView, setCurrentView] = useState<ImportViews>(initialView);

  const goToWorkOverlaps = useCallback(() => {
    setCurrentView(ImportViews.WorkOverlaps);
    trackStrategySelection('overlaps');
  }, []);
  const goToManual = useCallback(() => {
    setCurrentView(ImportViews.ManualImport);
    trackStrategySelection('csv');
  }, []);
  const goToChromeExtension = useCallback(() => {
    setCurrentView(ImportViews.ChromeExtension);
    trackStrategySelection('plugin');
  }, []);
  const goToImportGoogle = useCallback(() => {
    setCurrentView(ImportViews.GoogleImport);
    trackStrategySelection('google');
  }, []);
  const goToImportSuccess = useCallback(() => {
    setCurrentView(ImportViews.Success);
  }, []);

  const IntroScreen = useCallback(
    () => (
      <ImportConnectionsIntro
        goWorkOverlaps={goToWorkOverlaps}
        goImportManual={goToManual}
        goChromeExtension={goToChromeExtension}
        goImportGoogle={goToImportGoogle}
      />
    ),
    [goToWorkOverlaps, goToManual, goToChromeExtension, goToImportGoogle]
  );

  useEffect(() => {
    segmentTrack('Modal Viewed', {
      label: 'connections import',
      step: currentView === ImportViews.Intro ? 'strategies list' : 'strategy view',
    });
  }, [currentView]);

  switch (currentView) {
    case ImportViews.Intro:
      return <IntroScreen />;
    case ImportViews.WorkOverlaps:
      return <ImportConnectionsWorkOverlaps onClose={onClose} />;
    case ImportViews.CompanyMap:
      return <ImportConnectionsMapCompany onClose={onClose} />;
    case ImportViews.ManualImport:
      return <ImportConnectionsCSV onClose={onClose} goToSuccessScreen={goToImportSuccess} />;
    case ImportViews.ChromeExtension:
      return <ImportConnectionsChromeExtension onClose={onClose} goToManual={goToManual} />;
    case ImportViews.GoogleImport:
      return <ImportConnectionsGoogle onClose={onClose} />;
    case ImportViews.Success:
      return <ImportConnectionsSuccess onClose={onClose} />;
    default:
      return <IntroScreen />;
  }
};

export const ImportConnectionsModal = ({initialView, onClose, visible}: Props) => (
  <Modal
    className="w-[650px] font-light text-swarm-gray-700"
    visible={visible}
    onClose={onClose}
    closeButtonVisible
  >
    <Content onClose={onClose} initialView={initialView} />
  </Modal>
);
