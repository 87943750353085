import {TabLinkItem} from '../../components/tabs/types';
import {Role} from '../../types';
import {MembersListItem, MembersListItemStatus, MembersPageTabs} from './types';

export const useTabsConfig = (members: MembersListItem[]) => {
  const activeMembers = members.filter(member => {
    return member.status === MembersListItemStatus.Active && member.role !== Role.Connector;
  });
  const invites = members.filter(member => member.status === MembersListItemStatus.Invited);
  const connectors = members.filter(member => member.role === Role.Connector);

  const tabLinksConfig: TabLinkItem[] = [
    {
      title: MembersPageTabs.Joined,
      path: '/members',
      badge: ` (${activeMembers.length})`,
    },
    {
      title: MembersPageTabs.Connectors,
      path: '/members/connectors',
      badge: ` (${connectors.length})`,
    },
    {
      title: MembersPageTabs.Invited,
      path: '/members/invited',
      badge: ` (${invites.length})`,
    },
  ];

  return tabLinksConfig;
};
