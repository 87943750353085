import {AuthBox, AuthBoxIcon} from '../../../../../components/auth';
import {Button} from '../../../../../components/button';
import {LinkedinIcon, SwarmLogoIcon} from '../../../../../components/icons';

type Props = {
  onGetUserBlueUrl: () => void;
};
export const ConnectedToLinkedinScreen = ({onGetUserBlueUrl}: Props) => {
  return (
    <AuthBox
      headerImage={<HeaderImage />}
      headerTitle={
        <>
          Your LinkedIn account
          <br />
          is now connected
        </>
      }
    >
      <div className="flex flex-col gap-2">
        <p className="pb-4 text-base leading-6 text-swarm-gray-700">
          We have started syncing your LinkedIn Connections.
          <br />
          You will see them appearing in batches.
        </p>
        <Button
          variant="primary"
          fullWidth
          onClick={onGetUserBlueUrl}
          tracking={{
            label: 'login to linkedin',
            location: 'onboarding',
          }}
          size="lg"
        >
          Continue
        </Button>
      </div>
    </AuthBox>
  );
};

const HeaderImage = () => (
  <div className="flex items-center justify-center gap-4">
    <AuthBoxIcon>
      <LinkedinIcon className="w-8" />
    </AuthBoxIcon>
    <img className="h-6" src="/images/arrow-positive.png" alt="connected" />
    <AuthBoxIcon className="bg-swarm-gradient shadow-none">
      <SwarmLogoIcon className="w-8 pl-0.5" />
    </AuthBoxIcon>
  </div>
);
