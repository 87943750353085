import {useCallback, useState} from 'react';
import {Invitations, WorkOverlapsScreen} from './team-join-stages';

type Props = {
  goToCreateTeam: () => void;
  onSuccess: () => void;
};

enum JoinTeamScreens {
  Invitations = 'Invitations',
  WorkOverlaps = 'WorkOverlaps',
}

export const JoinTeamScreen = ({goToCreateTeam, onSuccess}: Props) => {
  const [currentView, setCurrentView] = useState<JoinTeamScreens>(JoinTeamScreens.Invitations);

  const goToWorkOverlaps = useCallback(() => setCurrentView(JoinTeamScreens.WorkOverlaps), []);

  switch (currentView) {
    case JoinTeamScreens.Invitations:
      return <Invitations onSuccess={goToWorkOverlaps} goToCreateTeam={goToCreateTeam} />;
    case JoinTeamScreens.WorkOverlaps:
      return <WorkOverlapsScreen onFinish={onSuccess} />;
    default:
      return <>Invalid screen</>;
  }
};
