import {
  FiBarChart as StrengthIcon,
  FiMapPin as CompanyLocationIcon,
  FiMapPin as LocationIcon,
  FiTag as TagsIcon,
  FiType as CompanyNameIcon,
  FiUsers as ConnectedToIcon,
} from 'react-icons/fi';
import {
  PiFactory as CompanyIndustryIcon,
  PiList as ListOfCompaniesIcon,
  PiUsersThree as CompanySizeIcon,
} from 'react-icons/pi';
import {TbTransfer as CrmsIcon} from 'react-icons/tb';
import {TiBusinessCard as CurrentJobTitleIcon} from 'react-icons/ti';
import {FilterKind, FiltersConfig} from '../../components/new-filters/types';
import {ActionPermission, Permission} from '../../types';

enum FilterCategory {
  PROFILE = 'profile',
  COMPANY = 'company',
  GENERAL = 'general',
}

const companyLevelPermission: ActionPermission = {
  permissionName: Permission.Company,
  featureName: 'company-level filters',
  location: 'connection company filters',
};

export const filtersConfig: FiltersConfig = {
  filtersName: 'peopleFilters',
  categories: [
    {
      key: FilterCategory.PROFILE,
      title: 'Profile Filters',
    },
    {
      key: FilterCategory.COMPANY,
      title: 'Companies Filters',
    },
    {
      key: FilterCategory.GENERAL,
      title: 'General Data',
    },
  ],
  filters: {
    currentJobTitle: {
      key: 'currentJobTitle',
      title: 'Job Title (Current)',
      icon: () => <CurrentJobTitleIcon />,
      category: FilterCategory.PROFILE,
      kind: FilterKind.ENUM,
    },
    allTimeJobTitle: {
      key: 'allTimeJobTitle',
      title: 'Job Title (All Time)',
      icon: () => <CurrentJobTitleIcon />,
      category: FilterCategory.PROFILE,
      kind: FilterKind.ENUM,
    },
    connectedTo: {
      key: 'connectedTo',
      title: 'Connected to',
      icon: () => <ConnectedToIcon />,
      category: FilterCategory.PROFILE,
      kind: FilterKind.ENUM,
      decorator: 'user',
    },
    location: {
      key: 'location',
      title: 'Location',
      icon: () => <LocationIcon />,
      category: FilterCategory.PROFILE,
      kind: FilterKind.ENUM,
    },
    teamConnectionStrength: {
      key: 'teamConnectionStrength', // TODO: teamGraph ? teamConnectionStrength : strength
      title: 'Strength',
      icon: () => <StrengthIcon />,
      category: FilterCategory.PROFILE,
      kind: FilterKind.ENUM,
      searchable: false,
      decorator: 'strength',
      permission: {
        permissionName: Permission.ConnectionStrength,
        featureName: 'Connection Strength',
        location: 'connection score filter',
      },
    },
    tag: {
      key: 'tag',
      title: 'Profile Tags',
      icon: () => <TagsIcon />,
      category: FilterCategory.PROFILE,
      kind: FilterKind.ENUM,
      decorator: 'tag',
      permission: {
        permissionName: Permission.Tag,
        featureName: 'Tags feature',
        location: 'tags filter',
      },
    },
    currentJobCompanyName: {
      key: 'currentJobCompanyName',
      title: 'Company Name',
      icon: () => <CompanyNameIcon />,
      category: FilterCategory.COMPANY,
      kind: FilterKind.ENUM,
      permission: companyLevelPermission,
    },
    currentJobCompanyNameList: {
      key: 'currentJobCompanyNameList',
      title: 'List of Companies',
      icon: () => <ListOfCompaniesIcon />,
      category: FilterCategory.COMPANY,
      kind: FilterKind.LIST,
      permission: companyLevelPermission,

      modalTitle: 'List of Companies',
      modalDescription: 'Enter company names below (separated by commas or line breaks)',
      tokensLabel: 'Recognized company names',
    },
    currentJobCompanyIndustry: {
      key: 'currentJobCompanyIndustry',
      title: 'Company Industry',
      icon: () => <CompanyIndustryIcon />,
      category: FilterCategory.COMPANY,
      kind: FilterKind.ENUM,
      permission: companyLevelPermission,
    },
    currentJobCompanySize: {
      key: 'currentJobCompanySize',
      title: 'Company Size',
      icon: () => <CompanySizeIcon />,
      category: FilterCategory.COMPANY,
      kind: FilterKind.ENUM,
      permission: companyLevelPermission,
    },
    currentJobCompanyLocation: {
      key: 'currentJobCompanyLocation',
      title: 'Company HQ',
      icon: () => <CompanyLocationIcon />,
      category: FilterCategory.COMPANY,
      kind: FilterKind.ENUM,
      permission: companyLevelPermission,
    },
    crm: {
      key: 'crm',
      title: 'CRM Source',
      icon: () => <CrmsIcon />,
      category: FilterCategory.GENERAL,
      kind: FilterKind.ENUM,
      decorator: 'crm',
      permission: {
        permissionName: Permission.CRM,
        featureName: 'CRM integration',
        location: 'crm filter',
      },
      searchable: false,
    },
  },
  availableFilters: [
    'currentJobTitle',
    'allTimeJobTitle',
    'connectedTo',
    'location',
    'teamConnectionStrength',
    'tag',
    'currentJobCompanyName',
    'currentJobCompanyNameList',
    'currentJobCompanyIndustry',
    'currentJobCompanySize',
    'currentJobCompanyLocation',
    'crm',
  ],
};
