import {AddressBook as ConnectionsIcon} from 'phosphor-react';
import {MouseEvent} from 'react';
import {FiUsers as MembersIcon} from 'react-icons/fi';
import {useIntercom} from 'react-use-intercom';
import {useQueryDashboard} from '../../api';
import {useImportConnectionsModal} from '../../contexts';
import {ImportStrategy, Permission} from '../../types';
import {getConfig, segmentTrack} from '../../utils';
import {LoadingSpinner} from '../animations';
import {GoogleIcon, LinkedinIcon, PremiumStarsIcon, WorkOverlapsIcon} from '../icons';
import {ImportViews} from '../import-connections';
import {PermissionChecker} from '../permission';
import {DashboardTask} from './DashboardTask';
import Styles from './DashboardTasks.module.scss';
import {TaskPurpose} from './types';

enum LearnMoreArticleIds {
  ReviewConnections = 8117404,
  AddMembers = 8443857,
}

const {newOnboardingEnabled} = getConfig();

export const DashboardTasks = () => {
  const {data} = useQueryDashboard();
  const {showImportModal} = useImportConnectionsModal();
  const {showArticle} = useIntercom();

  if (!data) {
    return <LoadingSpinner size="small" color="black" centered />;
  }

  const {
    imports: {overlaps, google, linkedIn},
  } = data;

  const openLearnMore = (articleId: LearnMoreArticleIds) => (e: MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
    showArticle(articleId);
  };

  return (
    <ul className={Styles.tasksList}>
      <DashboardTask
        type={TaskPurpose.overlaps}
        title="Add your work overlaps"
        description="People you may know"
        icon={<WorkOverlapsIcon className="w-6" />}
        count={overlaps.count}
        inProgress={overlaps.inProgress}
        onClick={() => {
          trackSelectImport('overlaps');
          showImportModal(ImportViews.WorkOverlaps);
        }}
      />
      {newOnboardingEnabled && (
        <PermissionChecker permission={Permission.AiNetworkMapper}>
          <DashboardTask
            type={TaskPurpose.companyMap}
            title="AI Network Mapper"
            description="Add Company LinkedIn URL and map your Company Network"
            icon={<PremiumStarsIcon className="h-4" />}
            onClick={() => {
              trackSelectImport('companyMap');
              showImportModal(ImportViews.CompanyMap);
            }}
          />
        </PermissionChecker>
      )}
      <DashboardTask
        type={TaskPurpose.linkedin}
        title="Import LinkedIn Connections"
        description="via our Chrome Extension or CSV"
        icon={<LinkedinIcon className="w-6" />}
        count={linkedIn.count}
        inProgress={linkedIn.inProgress}
        onClick={() => {
          trackSelectImport('plugin');
          showImportModal(ImportViews.ChromeExtension);
        }}
      />
      <DashboardTask
        type={TaskPurpose.google}
        title="Import Google Contacts & Calendar"
        description="via our Google integration"
        icon={<GoogleIcon className="w-6" />}
        count={google.count}
        inProgress={google.inProgress}
        onClick={() => {
          trackSelectImport('google');
          showImportModal(ImportViews.GoogleImport);
        }}
      />
      <DashboardTask
        type={TaskPurpose.review}
        title="Review your connections"
        description={
          <>
            Manually adjust your connection strength scores.{' '}
            <a
              className="cursor-pointer text-swarm-gray-800 underline hover:text-swarm-black"
              onClick={openLearnMore(LearnMoreArticleIds.ReviewConnections)}
            >
              Learn more
            </a>
          </>
        }
        icon={<ConnectionsIcon size={25} />}
      />
      <PermissionChecker permission={Permission.TeamMemberUpdate}>
        <DashboardTask
          type={TaskPurpose.addMembers}
          title="Add more members"
          description={
            <>
              Add colleagues and key stakeholders{' '}
              <a
                className="cursor-pointer text-swarm-gray-800 underline hover:text-swarm-black"
                onClick={openLearnMore(LearnMoreArticleIds.AddMembers)}
              >
                Learn more
              </a>
            </>
          }
          icon={<MembersIcon size={21} className="ml-1" />}
        />
      </PermissionChecker>
    </ul>
  );
};

const trackSelectImport = (strategy: ImportStrategy) => {
  segmentTrack('Button Clicked', {
    label: 'select import strategy',
    location: 'home page',
    strategy,
  });
};
