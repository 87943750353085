import {Table} from '../../../components/table';
import {ProfilesListRow} from '../../../types';
import {type UsePipelineProfilesTableResult} from './usePipelineProfilesTable';

type Props = {
  tableData: UsePipelineProfilesTableResult;
};

export const PipelineProfilesTable = ({tableData}: Props) => {
  return (
    <Table<ProfilesListRow>
      headerGroups={tableData.getHeaderGroups()}
      rowModel={tableData.getRowModel()}
      totalWidth={tableData.getTotalSize()}
    />
  );
};
