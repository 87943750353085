import {ProfilesBffColumns} from '../../defs';

export const MY_CONNECTIONS_COLUMNS_PREFERENCES_KEY = 'my_connections_columns';
export const availableColumns = [
  ProfilesBffColumns.FullName,
  ProfilesBffColumns.ConnectionsStrength,
  ProfilesBffColumns.JobTitle,
  ProfilesBffColumns.JobCompanyName,
  ProfilesBffColumns.LocationName,
];
