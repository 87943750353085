import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {segmentTrack} from '../../utils';

type UseIsFilterOpenProps = {
  title: string;
  externalIsOpen?: boolean;
  setExternalIsOpen?: Dispatch<SetStateAction<boolean>>;
};

export const useIsFilterOpen = ({
  title,
  externalIsOpen,
  setExternalIsOpen,
}: UseIsFilterOpenProps): [boolean, Dispatch<SetStateAction<boolean>>] => {
  const [isOpen, setIsOpen] = useState(false);

  const currentValue = externalIsOpen ?? isOpen;
  const setterFunction = setExternalIsOpen ?? setIsOpen;

  useEffect(() => {
    if (currentValue) {
      segmentTrack('Selector Opened', {
        label: 'filter',
        filter: title,
      });
    }
  }, [currentValue, title]);

  return [currentValue, setterFunction];
};
