import cs from 'classnames';
import {ReactNode, useCallback} from 'react';
import {FiX as RemoveAllIcon} from 'react-icons/fi';
import {BffFilterOptions} from '../../../types';
import {Button} from '../../button';
import {Tooltip} from '../../tooltip';
import {AddFilter} from '../add-filter';
import {Filter} from '../filter';
import {FiltersContextProvider, FiltersContextType} from '../FiltersContext';
import {FilterKey, FiltersConfig, FilterState} from '../types';
import Styles from './FiltersWrapper.module.scss';

type Props = Pick<FiltersContextType, 'filtersState' | 'useFetchOptions'> & {
  filtersConfig: FiltersConfig;
  onFilterSet: (filterKey: FilterKey, newState?: FilterState) => void;
  operatorSelect?: boolean;
  outOfStateFilters?: BffFilterOptions;
  additionalActionsSlot?: ReactNode;
  className?: string;
};

export const FiltersWrapper = ({
  filtersConfig,
  filtersState,
  onFilterSet,
  useFetchOptions,
  operatorSelect = false,
  outOfStateFilters,
  additionalActionsSlot,
  className,
}: Props) => {
  const addFilter = useCallback(
    (filterKey: string) => {
      onFilterSet(filterKey);
    },
    [onFilterSet]
  );

  const clearFilters = useCallback(() => {
    Object.keys(filtersState).forEach(filterKey => onFilterSet(filterKey));
  }, [filtersState, onFilterSet]);

  return (
    <FiltersContextProvider
      filtersState={filtersState}
      useFetchOptions={useFetchOptions}
      outOfStateFilters={outOfStateFilters}
    >
      <div className={cs(Styles.wrapper, className)}>
        {Object.entries(filtersState).map(([filterKey, filterValue]) => (
          <Filter
            filterConfig={filtersConfig.filters[filterKey]}
            filterState={filterValue}
            onChange={filterState => onFilterSet(filterKey, filterState)}
            onRemove={() => onFilterSet(filterKey, undefined)}
            key={filterKey}
            operatorSelect={operatorSelect}
          />
        ))}
        <AddFilter config={filtersConfig} onAddFilter={addFilter} currentFilters={filtersState} />
        {Object.keys(filtersState).length > 0 && (
          <Tooltip content="Clear all filters">
            <Button
              iconOnly
              outline
              icon={<RemoveAllIcon />}
              variant="tertiary"
              size="md"
              onClick={clearFilters}
              tracking={{label: 'clear filters'}}
            />
          </Tooltip>
        )}
        {additionalActionsSlot}
      </div>
    </FiltersContextProvider>
  );
};
