import {useMutation, UseMutationOptions} from 'react-query';
import {ErrorResponse, ScoreValue} from '../types';
import {apiClient} from './apiClient';

// SINGLE CONNECTION EDIT

type EditConnectionStrengthParams = {
  strength: ScoreValue;
  profileId: string;
};
const editConnectionStrength = async (params: EditConnectionStrengthParams) => {
  const {strength, profileId} = params;
  await apiClient.post(`/profiles/${profileId}/connections/strength`, {
    strength,
  });
};

export const useMutationEditConnectionStrength = (
  options: UseMutationOptions<void, ErrorResponse, EditConnectionStrengthParams>
) => useMutation(params => editConnectionStrength(params), options);

// BULK ACTION EDIT

type BulkEditConnectionStrengthInput = {
  strength: ScoreValue;
  profileId: string;
}[];
const bulkEditConnectionStrength = async (input: BulkEditConnectionStrengthInput) => {
  await apiClient.post('/profiles/connections/strength', input);
};

type BulkEditConnectionStrengthParams = {
  strength: ScoreValue;
  profileIds: string[];
};
export const useMutationBulkEditConnectionStrength = (
  options: UseMutationOptions<void, ErrorResponse, BulkEditConnectionStrengthParams>
) =>
  useMutation(params => {
    const {strength, profileIds} = params;
    const input = profileIds.map(profileId => ({strength, profileId}));
    return bulkEditConnectionStrength(input);
  }, options);
