/*
 * JIRA: https://theswarm.atlassian.net/browse/SWRM-2829
 */

import {useEffect, useState} from 'react';
import {SortOption} from '../../types';

const scoreSortOption: SortOption = {
  field: 'score',
  direction: 'desc',
};

export const useAdjustSortToQuery = (sort: SortOption[], searchQuery: string) => {
  const [lastProcessedQuery, setLastProcessedQuery] = useState(searchQuery);
  const [lastProcessedSort, setLastProcessedSort] = useState(JSON.stringify(sort));
  const [initiallyRenderedWithSearchQuery, setInitiallyRenderedWithSearchQuery] = useState(
    searchQuery.length > 0
  );
  const [adjustedSort, setAdjustedSort] = useState<SortOption[]>([...sort]);

  useEffect(() => {
    // if the page was initially rendered with a search query, wee need to preceed adjustedSort with score
    if (initiallyRenderedWithSearchQuery) {
      setInitiallyRenderedWithSearchQuery(false);
      setAdjustedSort([scoreSortOption, ...sort]);
      // if it's a rerender and the search query has changed
    } else if (lastProcessedQuery !== searchQuery) {
      setLastProcessedQuery(searchQuery);
      // and it's not empty, we need to preceed adjustedSort with score
      if (searchQuery.length > 0) {
        setAdjustedSort([scoreSortOption, ...sort]);
        // otherwise, search query has been cleared - and therefore we should remove score from adjustedSort
      } else {
        setAdjustedSort([...sort]);
      }
      // if it's rerender and searchQuery hasn't changed, but sort has
    } else if (lastProcessedSort !== JSON.stringify(sort)) {
      setLastProcessedSort(JSON.stringify(sort));
      setAdjustedSort([...sort]);
    }
  }, [searchQuery, sort, initiallyRenderedWithSearchQuery, lastProcessedQuery, lastProcessedSort]);

  return adjustedSort;
};
