import {PiPuzzlePiece as PuzzleIcon} from 'react-icons/pi';
import {AuthBox, AuthBoxIcon} from '../../../../../components/auth';
import {Button} from '../../../../../components/button';
import {extensionUrl} from '../../../../../utils';

type Props = {
  onSkip: () => void;
  onInstall: () => void;
};

export const InstallationTipsScreen = ({onSkip, onInstall}: Props) => {
  return (
    <AuthBox headerImage={<HeaderImage />} headerSubtitle="Install the extension">
      <ol className="mb-8 flex list-decimal flex-col gap-1 pl-5 text-start">
        <li>
          Ensure your browser support Chromium Extensions
          <br />
          (like Chrome, Edge, Brave, Opera etc.)
        </li>
        {/* TODO - there should be a 'learn more' link in above */}
        <li>
          Visit the{' '}
          <a className="underline" href={extensionUrl} target="_blank" rel="noreferrer">
            Chrome Web Store
          </a>
        </li>
        <li>Click 'Install' to add the extension to your browser</li>
        <li>Return to this page to continue with the next steps.</li>
      </ol>
      <Button
        variant="secondary"
        fullWidth
        onClick={onInstall}
        tracking={{
          label: 'Continue installation',
          location: 'onboarding',
        }}
        className="mb-2"
        size="lg"
      >
        Continue installation
      </Button>
      <Button
        variant="tertiary"
        fullWidth
        onClick={onSkip}
        tracking={{
          label: 'skip install extension',
          location: 'onboarding',
        }}
        size="lg"
      >
        I don't want to install now
      </Button>
    </AuthBox>
  );
};

const HeaderImage = () => (
  <AuthBoxIcon>
    <PuzzleIcon size={32} />
  </AuthBoxIcon>
);
