import {ReactNode} from 'react';
import {Arrow, Placement, useHover, useLayer} from 'react-laag';
import Styles from './Tooltip.module.scss';

type Props = {
  content: string | ReactNode;
  disabled?: boolean;
  placement?: Placement;
  offset?: number;
  hideHovered?: boolean;
  delayLeave?: number;
  children: ReactNode;
};

export const Tooltip = ({
  children,
  content,
  disabled,
  placement,
  offset,
  hideHovered,
  delayLeave = 0,
}: Props) => {
  const [isOver, hoverProps, close] = useHover({delayEnter: 100, delayLeave});

  const {triggerProps, layerProps, arrowProps, renderLayer} = useLayer({
    isOpen: isOver,
    triggerOffset: offset || 10,
    ...(placement ? {placement} : {auto: true}),
  });

  const tooltipArrowProps = {
    ...arrowProps,
    backgroundColor: '#1D1D1D',
  };

  const tooltipLayer =
    isOver &&
    renderLayer(
      <div className={Styles.tooltip} {...layerProps}>
        {content}
        <Arrow {...tooltipArrowProps} string="" onPointerEnterCapture={null} onPointerLeaveCapture={null} />
      </div>
    );

  return disabled ? (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  ) : hideHovered ? (
    <>
      <span {...triggerProps} {...hoverProps} onClick={() => close()}>
        {children}
      </span>
      {tooltipLayer}
    </>
  ) : (
    <div {...triggerProps} {...hoverProps} onClick={() => close()}>
      {children}
      {tooltipLayer}
    </div>
  );
};
